import axios from "axios";
import authHeader from "./jwt-token-access/auth-token-header";
import toastr from "toastr"

//apply base url for axios
const API_URL = "https://srv.municipiotransparente.com/api/";


//APPLY BASE URL FOR PLATAFORM FRONTEND
export const PORTAL_URL = process.env.REACT_APP_URL_PORTAL

const axiosApi = axios.create({
  baseURL: API_URL,
});


axiosApi.defaults.headers.common["Authorization"] = authHeader().Authorization;
axiosApi.defaults.headers.common["X-Cityhall-UUID"] = authHeader().cityhall?.uuid;

axiosApi.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    // Manejo del error
    if (error.response && error.response.status === 400) {
      toastr.error(error.response.data.message)
    }
    // Propaga otros errores
    return Promise.reject(error);
  }
);

axiosApi.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject(error)
);

export async function get(url, config = {}) {
  const axiosInstance = useAxiosWithCityhall();
  return await axiosApi
    .get(url, { ...config })
    .then((response) => response.data);
}

export async function post(url, data, config = {}) {
  return axiosApi
    .post(url, { ...data }, { ...config })
    .then((response) => response.data);
}

export async function put(url, data, config = {}) {
  return axiosApi
    .put(url, { ...data }, { ...config })
    .then((response) => response.data);
}

export async function del(url, config = {}) {
  return await axiosApi
    .delete(url, { ...config })
    .then((response) => response.data);
}

export async function upload(url, data, config= {}) {
  return axiosApi.post(url, data, { ...config })
    .then((response) => response.data);
}


export async function postAndDownload(url, data) {
  const headers = authHeader;
  headers["Content-Type"] = "application/json";
  return axiosApi.post(url, { ...data }, {
      url: url,
      method: 'POST',
      data: data,
      responseType: 'blob',
      headers:headers,
    }).then((response) => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', "doc.pdf");
    document.body.appendChild(link);
    link.click();
  });
}

export async function postAndGetPdf(url, data) {
  const headers = authHeader;
  headers["Content-Type"] = "application/json";
  return axiosApi.post(url, { ...data }, {
    url: url,
    method: 'POST',
    data: data,
    responseType: 'blob',
    headers:headers,
  }).then((response) => URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' })));
}


export default axiosApi;