import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import {
  Button,
  Col,
  Form,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap"

import * as Yup from "yup";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { uploadInvoicesRows as onUploadInvoicesRows } from "store/actions";

const InvoiceUploadModal = props => {
  const { isOpen, toggle } = props

  const dispatch = useDispatch()

  const [fileU,setFileU] = useState()

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      file: '',
    },
    validationSchema: Yup.object({
      //file: Yup.string().required("Por favor agrega el archivo para el upload"),
    }),
    onSubmit: (values) => {


      const formData = new FormData()
        formData.append("file", fileU)
        try {
            //dispatch(onUploadImage(formData))
            dispatch(onUploadInvoicesRows(formData))
                
        } catch (err) {
            //toastr.error("Hubo un error al subir la imagen, intenta nuevamente", "Plataforma Guelaguetza")
        } 
      
      //validation.resetForm();
      //toggle();
    },
  });

  const handleFileUpload  = async (event) => {
    
  
    try {
        setFileU(event.target.files[0])
        
    } catch (error) {
        console.log(error);
    }

}


  useEffect(()=> {
    validation.resetForm()
  },[]);

  return (
    <Modal
      isOpen={isOpen}
      role="dialog"
      autoFocus={true}
      centered={true}
      className="exampleModal"
      tabIndex="-1"
      toggle={toggle}
    >
      <div className="modal-content">
        <Form>
        <ModalHeader toggle={toggle}>Carga de Facturas</ModalHeader>
        <ModalBody>
            <Row>
              <Col lg={12}>
                <div className="mb-3">
                      <Label className="form-label">Selecciona un Archivo de ZIP</Label>
                      <Input
                        name="file"
                        type="file"
                        placeholder=""
                        validate={{
                          required: { value: true },
                        }} accept="zip,application/octet-stream,application/zip,application/x-zip,application/x-zip-compressed" 
                        onChange={handleFileUpload} 
                        onBlur={validation.handleBlur}
                        //value={validation.values.file || ""}
                        invalid={
                          validation.touched.file && validation.errors.file ? true : false
                        } 
                        
                      />
                      {validation.touched.file && validation.errors.file ? (
                        <FormFeedback type="invalid">{validation.errors.file}</FormFeedback>
                      ) : null}
                    </div>
              </Col>
            </Row>
        </ModalBody>
        <ModalFooter>
          <Button type="button" color="secondary" onClick={toggle}>
            Cancelar
          </Button>
          <Button onClick={(e)=>{
            e.preventDefault();
            validation.handleSubmit();
          }} type="submit" color="primary">
            Enviar
          </Button>
        </ModalFooter>
        </Form>
      </div>
    </Modal>
  )
}

InvoiceUploadModal.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
}

export default InvoiceUploadModal
