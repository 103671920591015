import React, { useState } from "react";
import { Button, Card, CardBody, CardFooter, CardTitle, Col, Form, FormFeedback, Input, Label, Row } from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";

import { updateConfigInit as onUpdateConfigInit } from "../../store/Config/actions"
import { useActiveCityhall } from "../../context/ActiveCityhallContext"

const FormCityhallConf = ({edit=false}) => {

    const dispatch = useDispatch()
  const { activeCityhall } = useActiveCityhall();

    const { cityHall, period } = useSelector(state => ({
        cityHall: state.ConfigInit.cityHall,
        period: state.ConfigInit.period
    }));

    const validation = useFormik({
        enableReinitialize: true,

        initialValues: {
            id: cityHall!==null && edit ? cityHall.id:null,
            name: cityHall!==null && edit ? cityHall.name:"",
            rfc: cityHall!==null && edit ? cityHall.rfc:"",
            //yearIni: period!==null && period.lenght>0 ? period[0]:"",
            //yearEnd:(period!==null && period.lenght>0) ? period[1]:"",
        },
        validationSchema: Yup.object({
            name: Yup.string().required("Ingresa el nombre del municipio"),
            rfc: Yup.string().required("Ingresa el RFC del municipio"),
            //yearIni: Yup.string().required("Ingresa el año inicial del periodo"),
            //yearEnd: Yup.string().required("Ingresa el año final del periodo"),
        }),
        onSubmit: (values) => {
          dispatch(onUpdateConfigInit(values))
        },
    });

    return (
        <>
        <Form onSubmit={(e)=>{
            e.preventDefault();
            validation.handleSubmit();
        }}>
            <Card>
                <CardBody>
                  <CardTitle>Configuración del Municipio</CardTitle>
                  <h6 className="text-success mt-4">
                  </h6>
                  <Form>
                    <Row>
                        <Col sm="12">
                            <Row>
                                <Col sm="12">
                                <div className="mb-3">
                                    <Label htmlFor="name">Nombre del Municipio {edit ? "Activo":""}</Label>
                                    <Input
                                        id="name"
                                        name="name"
                                        type="text"
                                        className="form-control"
                                        placeholder=""
                                        onChange={validation.handleChange} 
                                        onBlur={validation.handleBlur}
                                        value={validation.values.name || ""}
                                        invalid={
                                            validation.touched.name 
                                            && validation.errors.name ? true : false
                                        }
                                    />
                                    {validation.touched.name && validation.errors.name ? (
                                        <FormFeedback type="invalid">{validation.errors.name}</FormFeedback>
                                    ) : null}
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                      <Col sm="12">
                        <Row>
                          <Col sm="12">
                            <div className="mb-3">
                              <Label htmlFor="name">RFC del Municipio {edit ? "Activo":""}</Label>
                              <Input
                                id="rfc"
                                name="rfc"
                                type="text"
                                className="form-control"
                                placeholder=""
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.rfc || ""}
                                invalid={
                                  validation.touched.rfc
                                  && validation.errors.rfc ? true : false
                                }
                              />
                              {validation.touched.rfc && validation.errors.rfc ? (
                                <FormFeedback type="invalid">{validation.errors.rfc}</FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                        </Row>
                      </Col>
                        <Col sm="6" style={{display:"none"}}>
                        <Row>
                            <Col sm="6">
                                <div className="mb-3">
                                <Label htmlFor="yearIni">Año Inicial del Periodo</Label>
                                <Input
                                    id="yearIni"
                                    name="yearIni"
                                    type="number"
                                    className="form-control"
                                    placeholder="" min={2020} max={2100}
                                    onChange={validation.handleChange} 
                                    onBlur={validation.handleBlur}
                                    value={validation.values.yearIni || ""}
                                    invalid={
                                        validation.touched.yearIni 
                                        && validation.errors.yearIni ? true : false
                                    }
                                />
                                {validation.touched.yearIni && validation.errors.yearIni ? (
                                    <FormFeedback type="invalid">{validation.errors.yearIni}</FormFeedback>
                                ) : null}
                                </div>
                            </Col>

                            <Col sm="6">
                                <div className="mb-3">
                                <Label className="control-label">Año Final del Periodo</Label>
                                <Input
                                    id="yearEnd"
                                    name="yearEnd"
                                    type="number"
                                    className="form-control"
                                    placeholder="" min={2020} max={2100}
                                    onChange={validation.handleChange} 
                                    onBlur={validation.handleBlur}
                                    value={validation.values.yearEnd || ""}
                                    invalid={
                                        validation.touched.yearEnd 
                                        && validation.errors.yearEnd ? true : false
                                    }
                                />
                                {validation.touched.yearEnd && validation.errors.yearEnd ? (
                                    <FormFeedback type="invalid">{validation.errors.yearEnd}</FormFeedback>
                                ) : null}
                                </div>
                            </Col>
                        </Row>
                        </Col>
                    </Row>
                  </Form>
                </CardBody>
                <CardFooter>
                        <div className="text-end mb-3">
                            <Button type="submit" color="primary" className="btn ">
                                Guardar Cambios
                            </Button>
                        </div>
                </CardFooter>
              </Card>
            </Form>
        </>
    )
}

export default FormCityhallConf