import React, { useEffect, useMemo, useState } from "react";
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { ceil, isEmpty } from "lodash";
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import TableContainer from  '../../components/Common/TableContainer';
import * as Yup from "yup";
import { useFormik } from "formik";
import toastr from "toastr";

//import components
import Breadcrumbs from '../../components/Common/Breadcrumb';

import {getInvoicesList as onGetInvoicesList} from "../../store/invoices/actions"

import {
  OrderId,
  BillingName,
  Date,
  Total,
  PaymentStatus,
  PaymentMethod
}
  from "./EcommerceOrderCol";

//redux
import { useSelector, useDispatch } from "react-redux";
import InvoiceUploadModal from "./InvoiceUploadModal";

import {
  Button,
  Col,
  Row,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Input,
  FormFeedback,
  Label,
  Card,
  CardBody,
  Badge,
} from "reactstrap";
import GenerateRequisitionModal from "./GenerateRequisitionModal";
import InvoiceDetailModal from "./InvoiceDetailModal";

function Invoices() {

  const dispatch = useDispatch()
  
  const { invoiceList,uploadInvoicesSuccess, activeTab } = useSelector(state => ({
    invoiceList: state.Invoices.invoiceList,
    uploadInvoicesSuccess: state.Invoices.uploadInvoicesSuccess,
    activeTab: state.Requisitions.activeTab
  }));

  //meta title
  document.title = "Facturas | Platform";

  const [modal, setModal] = useState(false);
  const [modal1, setModal1] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [viewInvDetShow, setViewInvDetShow] = useState(false);

  const [modalGenerateReqShow, setModalGenerateReqShow] = useState(false);

  const [invoices, setInvoices] = useState([]);
  const [order, setOrder] = useState(null);


  const toggleViewModal = () => setModal1(!modal1);
  const toggleGenerateRefModal = () => {
    if(invoicesAdd.length<=0) {
      toastr.error("Agrega al menos una factura para la requisisión")
      return false;
    }
    setModalGenerateReqShow(!modalGenerateReqShow)
  };

  const [invoiceView, setInvoiceView] = useState({});
  const toggleInvDetModal = (data) => {
    setInvoiceView(data)
    setViewInvDetShow(!viewInvDetShow);
  } 

  const [invoicesAdd, setInvoicesAdd] = useState([]);
  const handleProductInShipping = (data,e) => {
    const invoicesAddL = invoicesAdd
    let invExists = invoicesAddL.findIndex(item=> item.uuid===data.uuid);
    if(invExists===-1 && e.target.checked) {
      let rfcExists = invoicesAddL.findIndex(item=> item.issuer_rfc===data.issuer_rfc);
      if(invoicesAddL.length>0 && rfcExists===-1) {
        toastr.warning("Solo se puede agregar facturas del mismo proveedor para la requisición")
        return false;
      }
      invoicesAddL.push(data);
    }
    if(invExists>-1 && !e.target.checked) {
      invoicesAddL.splice(invExists,1);
    }
    setInvoicesAdd(invoicesAddL)
}


  const columns = useMemo(
    () => [

      {
        Header: 'Requisición No.',
        accessor: 'requisition_id',
        filterable: false,
        Cell: (cellProps) => {
          const invoiceData = cellProps.row.original;
          return invoiceData.requisition_id ?
          <Badge className="font-size-12" color={invoiceData.requisition_status==="Completo"?"success":"warning"}>
            {invoiceData.requisition_id}
          </Badge>
          :"";
        }
      },
      {
        Header: 'UUID',
        accessor: 'uuid',
        width: '150px',
        style: {
          textAlign: "center",
          width: "10%",
          background: "#0000",
        },
        filterable: true,
        Cell: (cellProps) => {
          return <OrderId {...cellProps} />;
        }
      },
      {
        Header: 'Proveedor',
        accessor: 'issuer_business_name',
        filterable: true,
        Cell: (cellProps) => {
          return <BillingName {...cellProps} />;
        }
      },
      {
        Header: 'Fecha',
        accessor: 'stamped_date_f',
        filterable: true,
        Cell: (cellProps) => {
          return <Date {...cellProps} />;
        }
      },
      {
        Header: 'Total',
        accessor: 'total',
        filterable: true,
        Cell: (cellProps) => {
          return <Total {...cellProps} />;
        }
      },
      {
        Header: 'status',
        accessor: 'status',
        filterable: true,
        Cell: (cellProps) => {
          return <PaymentStatus {...cellProps} />;
        }
      },
      {
        Header: 'Ver Detalles',
        className:"text-center",
        accessor: 'view',
        disableFilters: true,
        Cell: (cellProps) => {
          return (
            <Button
              type="button"
              color="primary"
              className="btn-sm btn-rounded"
              onClick={()=>toggleInvDetModal(cellProps.row.original)}
            >
              <i className="mdi mdi-monitor-eye"/>
            </Button>);
        }
      },
      {
        Header: 'Opciones',
        accessor: 'action',
        disableFilters: true,
        Cell: (cellProps) => {
          const invoiceData = cellProps.row.original;
          return (
            <div className="d-flex gap-3">
                            {
                                !invoiceData.requisition_id && invoiceData.status==="Vigente" ?
                                    (<><Input onClick={(e)=>handleProductInShipping(cellProps.row.original, e)} type={"checkbox"}></Input><label>Incluir</label></>)
                                  :
                                  <Badge className="font-size-12" color={invoiceData.requisition_status==="Completo"?"success":"warning"}>
                                    {invoiceData.requisition_status}
                                  </Badge>
                            }
                
            </div>
          );
        }
      },
    ],
    []
  );

  useEffect(()=> {
    dispatch(onGetInvoicesList());
  },[]);

  useEffect(()=>{
    if(uploadInvoicesSuccess) {
      toggleViewModal()
    }
  },[uploadInvoicesSuccess])

  useEffect(()=> {
    if(activeTab===5) {
      toggleGenerateRefModal()
    }
  }, [activeTab])
  return (
    <React.Fragment>
      <InvoiceUploadModal isOpen={modal1} toggle={toggleViewModal} />
     
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Listado" breadcrumbItem="Facturas" />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <TableContainer
                    columns={columns}
                    data={invoiceList}
                    isGlobalFilter={true}
                    isAddOption={true}
                    handleOptionClick={toggleGenerateRefModal}
                    labelOption={"Generar Requisición"}
                    customPageSize={10}
                  />
                  <Button type="button" onClick={toggleViewModal}>Nueva Carga de Facturas</Button>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <GenerateRequisitionModal invoicesAdd={invoicesAdd} isOpen={modalGenerateReqShow} toggle={toggleGenerateRefModal} />
          <InvoiceDetailModal invoice={invoiceView} isOpen={viewInvDetShow} toggle={toggleInvDetModal}/>
        </div>
      </div>
    </React.Fragment>
  );
}
Invoices.propTypes = {
  preGlobalFilteredRows: PropTypes.any,

};


export default Invoices;