import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import {
  Button,
  Col,
  Form,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Table,
} from "reactstrap"
import { formatCurrency } from "common/Utils"

const InvoiceDetailModal = props => {
  const { isOpen, toggle, invoice } = props

  return (
    <Modal
      isOpen={isOpen} size="lg"
      role="dialog"
      autoFocus={true}
      centered={true}
     
      tabIndex="-1"
      toggle={toggle}
    >
      <div className="modal-content">
        <ModalHeader toggle={toggle}> Detalle de la Factura (informativo) </ModalHeader>
        <ModalBody>
          <Row>
            <Col>
              <Table>
                <thead>
                  <tr>
                    <th>
                      Concepto
                    </th>
                    <th>Cantidad</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    invoice?.det?.map((d,key)=>{
                    return(
                      <tr key={key}>
                        <td>{d.description}</td>
                        <td>{d.quantity}</td>
                      </tr>)
                    })
                  }
                  <tr><td colSpan={2}>TOTAL </td><td>{formatCurrency(invoice?.total ? invoice?.total:0)}</td></tr>
                </tbody>
              </Table>
            </Col>
          </Row>   
        </ModalBody>
      </div>
    </Modal>
  )
}

InvoiceDetailModal.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
}

export default InvoiceDetailModal
