import React, { useEffect, useState } from "react";
import { Button, Card, CardBody, CardFooter, CardTitle, Col, Form, FormFeedback, Input, Label, Row } from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";

import { updateComissionFinance as onUpdateComissionFinance, getAreas as onGetAreas } from "../../store/Config/actions"

const FormComissionFinance = props => {

    const dispatch = useDispatch()

    const { comissionFinance, areas } = useSelector(state => ({
        comissionFinance: state.ConfigInit.comissionFinance,
        areas: state.ConfigInit.areas,
    }));

    const validation = useFormik({
        enableReinitialize: true,

        initialValues: {
            presiName: comissionFinance?.president!==null ? comissionFinance?.president?.name:"",
            presiPaternal: comissionFinance?.president!==null ? comissionFinance?.president?.paternal:"",
            presiMaternal: comissionFinance?.president!==null ? comissionFinance?.president?.maternal:"",
            presiPosition: comissionFinance?.president!==null ? comissionFinance?.president?.position:"Presidente Municipal",
            presiArea: comissionFinance?.president!==null ? comissionFinance?.president?.area:"",
            presiCodeKey: comissionFinance?.president!==null ? comissionFinance?.president?.codeKey:"",
            
            trusteeName: comissionFinance?.trustee!==null ? comissionFinance?.trustee?.name:"",
            trusteePaternal: comissionFinance?.trustee!==null ? comissionFinance?.trustee?.paternal:"",
            trusteeMaternal: comissionFinance?.trustee!==null ? comissionFinance?.trustee?.maternal:"",
            trusteePosition: comissionFinance?.trustee!==null ? comissionFinance?.trustee?.position:"Síndico Municipal",
            trusteeArea: comissionFinance?.trustee!==null ? comissionFinance?.trustee?.area:"",
            trusteeCodeKey: comissionFinance?.trustee!==null ? comissionFinance?.trustee?.codeKey:"",

            financeName: comissionFinance?.finance!==null ? comissionFinance?.finance?.name:"",
            financePaternal: comissionFinance?.finance!==null ? comissionFinance?.finance?.paternal:"",
            financeMaternal: comissionFinance?.finance!==null ? comissionFinance?.finance?.maternal:"",
            financePosition: comissionFinance?.finance!==null ? comissionFinance?.finance?.position:"Regidor de Hacienda",
            financeArea: comissionFinance?.finance!==null ? comissionFinance?.finance?.area:"",
            financeCodeKey: comissionFinance?.finance!==null ? comissionFinance?.finance?.codeKey:"",

            treasurerName: comissionFinance?.treasurer!==null ? comissionFinance?.treasurer?.name:"",
            treasurerPaternal: comissionFinance?.treasurer!==null ? comissionFinance?.treasurer?.paternal:"",
            treasurerMaternal: comissionFinance?.treasurer!==null ? comissionFinance?.treasurer?.maternal:"",
            treasurerPosition: comissionFinance?.treasurer!==null ? comissionFinance?.treasurer?.position:"Tesorero Muncipal",
            treasurerArea: comissionFinance?.treasurer!==null ? comissionFinance?.treasurer?.area:"",
            treasurerCodeKey: comissionFinance?.treasurer!==null ? comissionFinance?.treasurer?.codeKey:"",
        },
        validationSchema: Yup.object({
          presiName: Yup.string().required("*"),
          presiPaternal: Yup.string().required("*"),
          presiMaternal: Yup.string().required("*"),
          presiPosition: Yup.string().required("*"),

          presiCodeKey: Yup.string().required("*"),
          
          trusteeName: Yup.string().required("*"),
          trusteePaternal: Yup.string().required("*"),
          trusteeMaternal: Yup.string().required("*"),
          trusteePosition: Yup.string().required("*"),

          trusteeCodeKey: Yup.string().required("*"),
          
          financeName: Yup.string().required("*"),
          financePaternal: Yup.string().required("*"),
          financeMaternal: Yup.string().required("*"),
          financePosition: Yup.string().required("*"),

          financeCodeKey: Yup.string().required("*"),
          
          treasurerName: Yup.string().required("*"),
          treasurerPaternal: Yup.string().required("*"),
          treasurerMaternal: Yup.string().required("*"),
          treasurerPosition: Yup.string().required("*"),

          treasurerCodeKey: Yup.string().required("*"),

        }),
        onSubmit: (values) => {
            
            dispatch(onUpdateComissionFinance(values))
        },
    });

    useEffect(()=> {
      dispatch(onGetAreas({active:true}))
    },[]);

    return (
        <>
        <Form onSubmit={(e)=>{
            e.preventDefault();
            
            validation.handleSubmit();
        }}>

            <Card>
                <CardBody>
                  <CardTitle>Comisión de Hacienda</CardTitle>
                  <h6 className="text-success mt-4">
                    Presidente Municipal
                  </h6>
                    <Row>
                      <Col sm="4">
                        <div className="mb-3">
                          <Label htmlFor="presiName">Nombre(s)</Label>
                          <Input
                            id="presiName"
                            name="presiName"
                            type="text"
                            className="form-control"
                            placeholder=""
                            onChange={validation.handleChange} 
                            onBlur={validation.handleBlur}
                            value={validation.values.presiName || ""}
                            invalid={
                                validation.touched.presiName 
                                && validation.errors.presiName ? true : false
                            }
                        />
                        {validation.touched.presiName && validation.errors.presiName ? (
                            <FormFeedback type="invalid">{validation.errors.presiName}</FormFeedback>
                        ) : null}
                        </div>
                        
                      </Col>

                      <Col sm="4">
                        <div className="mb-3">
                          <Label className="control-label">Apellido Paterno</Label>
                          <Input
                            id="presiPaternal"
                            name="presiPaternal"
                            type="text"
                            className="form-control"
                            placeholder=""
                            onChange={validation.handleChange} 
                            onBlur={validation.handleBlur}
                            value={validation.values.presiPaternal || ""}
                            invalid={
                                validation.touched.presiPaternal 
                                && validation.errors.presiPaternal ? true : false
                            }
                        />
                        {validation.touched.presiPaternal && validation.errors.presiPaternal ? (
                            <FormFeedback type="invalid">{validation.errors.presiPaternal}</FormFeedback>
                        ) : null}
                        </div>
                        <div className="mb-3">
                          <Label className="control-label">Cargo</Label>
                          <Input
                            id="presiPosition"
                            name="presiPosition"
                            type="text"
                            className="form-control"
                            placeholder=""
                            onChange={validation.handleChange} 
                            onBlur={validation.handleBlur}
                            value={validation.values.presiPosition || ""}
                            invalid={
                                validation.touched.presiPosition 
                                && validation.errors.presiPosition ? true : false
                            }
                        />
                        {validation.touched.presiPosition && validation.errors.presiPosition ? (
                            <FormFeedback type="invalid">{validation.errors.presiPosition}</FormFeedback>
                        ) : null}
                        </div>
                      </Col>
                      <Col sm="4">
                        <div className="mb-3">
                          <Label className="control-label">Apellido Materno</Label>
                          <Input
                            id="presiMaternal"
                            name="presiMaternal"
                            type="text"
                            className="form-control"
                            placeholder="" onChange={validation.handleChange} 
                            onBlur={validation.handleBlur}
                            value={validation.values.presiMaternal || ""}
                            invalid={
                                validation.touched.presiMaternal 
                                && validation.errors.presiMaternal ? true : false
                            }
                        />
                        {validation.touched.presiMaternal && validation.errors.presiMaternal ? (
                            <FormFeedback type="invalid">{validation.errors.presiMaternal}</FormFeedback>
                        ) : null}
                        </div>
                        <div className="mb-3">
                          <Label className="control-label">Clave Para Oficios</Label>
                          <Input name="presiCodeKey" type="text"
                            
                            placeholder=""
                
                            
                            onChange={validation.handleChange} 
                            onBlur={validation.handleBlur}
                            value={validation.values.presiCodeKey || ""}
                            invalid={
                                validation.touched.presiCodeKey 
                                && validation.errors.presiCodeKey ? true : false
                            }
                            >
                            
                            </Input>
                        {validation.touched.presiCodeKey && validation.errors.presiCodeKey ? (
                            <FormFeedback type="invalid">{validation.errors.presiCodeKey}</FormFeedback>
                        ) : null}
                        </div>
                      
                      </Col>
                    </Row>
                    <h6 className="text-success mt-4">
                        Síndico Municipal
                    </h6>
                    <Row>
                      <Col sm="4">
                        <div className="mb-3">
                          <Label htmlFor="trusteeName">Nombre(s)</Label>
                          <Input
                            id="trusteeName"
                            name="trusteeName"
                            type="text"
                            className="form-control"
                            placeholder="Nombre (s)"
                            onChange={validation.handleChange} 
                            onBlur={validation.handleBlur}
                            value={validation.values.trusteeName || ""}
                            invalid={
                                validation.touched.trusteeName 
                                && validation.errors.trusteeName ? true : false
                            }
                        />
                        {validation.touched.trusteeName && validation.errors.trusteeName ? (
                            <FormFeedback type="invalid">{validation.errors.trusteeName}</FormFeedback>
                        ) : null}
                        </div>
                        
                      </Col>

                      <Col sm="4">
                        <div className="mb-3">
                          <Label className="control-label">Apellido Paterno</Label>
                          <Input
                            id="trusteePaternal"
                            name="trusteePaternal"
                            type="text"
                            className="form-control"
                            placeholder=""
                            onChange={validation.handleChange} 
                            onBlur={validation.handleBlur}
                            value={validation.values.trusteePaternal || ""}
                            invalid={
                                validation.touched.trusteePaternal 
                                && validation.errors.trusteePaternal ? true : false
                            }
                        />
                        {validation.touched.trusteePaternal && validation.errors.trusteePaternal ? (
                            <FormFeedback type="invalid">{validation.errors.trusteePaternal}</FormFeedback>
                        ) : null}
                        </div>
                        <div className="mb-3">
                          <Label className="control-label">Cargo</Label>
                          <Input
                            id="trusteePosition"
                            name="trusteePosition"
                            type="text"
                            className="form-control"
                            placeholder=""
                            onChange={validation.handleChange} 
                            onBlur={validation.handleBlur}
                            value={validation.values.trusteePosition || ""}
                            invalid={
                                validation.touched.trusteePosition 
                                && validation.errors.trusteePosition ? true : false
                            }
                        />
                        {validation.touched.trusteePosition && validation.errors.trusteePosition ? (
                            <FormFeedback type="invalid">{validation.errors.trusteePosition}</FormFeedback>
                        ) : null}
                        </div>
                      </Col>
                      <Col sm="4">
                        <div className="mb-3">
                          <Label className="control-label">Apellido Materno</Label>
                          <Input
                            id="trusteeMaternal"
                            name="trusteeMaternal"
                            type="text"
                            className="form-control"
                            placeholder=""
                            onChange={validation.handleChange} 
                            onBlur={validation.handleBlur}
                            value={validation.values.trusteeMaternal || ""}
                            invalid={
                                validation.touched.trusteeMaternal 
                                && validation.errors.trusteeMaternal ? true : false
                            }
                        />
                        {validation.touched.trusteeMaternal && validation.errors.trusteeMaternal ? (
                            <FormFeedback type="invalid">{validation.errors.trusteeMaternal}</FormFeedback>
                        ) : null}
                        </div>
                        <div className="mb-3">
                          <Label className="control-label">Clave Para Oficios</Label>
                          <Input name="trusteeCodeKey" type="text"

                            placeholder=""
                           

                            onChange={validation.handleChange} 
                            onBlur={validation.handleBlur}
                            value={validation.values.trusteeCodeKey || ""}
                            invalid={
                                validation.touched.trusteeCodeKey 
                                && validation.errors.trusteeCodeKey ? true : false
                            }
                            >
                            </Input>
                        {validation.touched.trusteeCodeKey && validation.errors.trusteeCodeKey ? (
                            <FormFeedback type="invalid">{validation.errors.trusteeCodeKey}</FormFeedback>
                        ) : null}
                        </div>
                      
                      </Col>
                    </Row>
                    <h6 className="text-success mt-4">
                        Regidor de Hacienda
                    </h6>
                    <Row>
                      <Col sm="4">
                        <div className="mb-3">
                          <Label htmlFor="financeName">Nombre(s)</Label>
                          <Input
                            id="financeName"
                            name="financeName"
                            type="text"
                            className="form-control"
                            placeholder="Nombre (s)" 
                            onChange={validation.handleChange} 
                            onBlur={validation.handleBlur}
                            value={validation.values.financeName || ""}
                            invalid={
                                validation.touched.financeName 
                                && validation.errors.financeName ? true : false
                            }
                        />
                        {validation.touched.financeName && validation.errors.financeName ? (
                            <FormFeedback type="invalid">{validation.errors.financeName}</FormFeedback>
                        ) : null}
                        </div>
                        
                      </Col>

                      <Col sm="4">
                        <div className="mb-3">
                          <Label className="control-label">Apellido Paterno</Label>
                          <Input
                            id="financePaternal"
                            name="financePaternal"
                            type="text"
                            className="form-control"
                            placeholder="" onChange={validation.handleChange} 
                            onBlur={validation.handleBlur}
                            value={validation.values.financePaternal || ""}
                            invalid={
                                validation.touched.financePaternal 
                                && validation.errors.financePaternal ? true : false
                            }
                        />
                        {validation.touched.financePaternal && validation.errors.financePaternal ? (
                            <FormFeedback type="invalid">{validation.errors.financePaternal}</FormFeedback>
                        ) : null}
                        </div>
                        <div className="mb-3">
                          <Label className="control-label">Cargo</Label>
                          <Input
                            id="financePosition"
                            name="financePosition"
                            type="text"
                            className="form-control"
                            placeholder=""
                            onChange={validation.handleChange} 
                            onBlur={validation.handleBlur}
                            value={validation.values.financePosition || ""}
                            invalid={
                                validation.touched.financePosition 
                                && validation.errors.financePosition ? true : false
                            }
                        />
                        {validation.touched.financePosition && validation.errors.financePosition ? (
                            <FormFeedback type="invalid">{validation.errors.financePosition}</FormFeedback>
                        ) : null}
                        </div>
                      </Col>
                      <Col sm="4">
                        <div className="mb-3">
                          <Label className="control-label">Apellido Materno</Label>
                          <Input
                            id="financeMaternal"
                            name="financeMaternal"
                            type="text"
                            className="form-control"
                            placeholder=""
                            onChange={validation.handleChange} 
                            onBlur={validation.handleBlur}
                            value={validation.values.financeMaternal || ""}
                            invalid={
                                validation.touched.financeMaternal 
                                && validation.errors.financeMaternal ? true : false
                            }
                        />
                        {validation.touched.financeMaternal && validation.errors.financeMaternal ? (
                            <FormFeedback type="invalid">{validation.errors.financeMaternal}</FormFeedback>
                        ) : null}
                        </div>
                        <div className="mb-3">
                          <Label className="control-label">Clave para Oficios</Label>
                          <Input name="financeCodeKey"
                             type="text"

                            onChange={validation.handleChange} 
                            onBlur={validation.handleBlur}
                            value={validation.values.financeCodeKey || ""}
                            invalid={
                                validation.touched.financeCodeKey 
                                && validation.errors.financeCodeKey ? true : false
                            }
                            >
                            </Input>
                        {validation.touched.financeCodeKey && validation.errors.financeCodeKey ? (
                            <FormFeedback type="invalid">{validation.errors.financeCodeKey}</FormFeedback>
                        ) : null}
                        </div>
                      
                      </Col>
                    </Row>
                    <h6 className="text-success mt-4">
                        Tesorero Municipal
                    </h6>
                    <Row>
                      <Col sm="4">
                        <div className="mb-3">
                          <Label htmlFor="treasurerName">Nombre(s)</Label>
                          <Input
                            id="treasurerName"
                            name="treasurerName"
                            type="text"
                            className="form-control"
                            placeholder="Nombre (s)" 
                            onChange={validation.handleChange} 
                            onBlur={validation.handleBlur}
                            value={validation.values.treasurerName || ""}
                            invalid={
                                validation.touched.treasurerName 
                                && validation.errors.treasurerName ? true : false
                            }
                        />
                        {validation.touched.treasurerName && validation.errors.treasurerName ? (
                            <FormFeedback type="invalid">{validation.errors.treasurerName}</FormFeedback>
                        ) : null}
                        </div>
                        
                      </Col>

                      <Col sm="4">
                        <div className="mb-3">
                          <Label className="control-label">Apellido Paterno</Label>
                          <Input
                            id="treasurerPaternal"
                            name="treasurerPaternal"
                            type="text"
                            className="form-control"
                            placeholder="" onChange={validation.handleChange} 
                            onBlur={validation.handleBlur}
                            value={validation.values.treasurerPaternal || ""}
                            invalid={
                                validation.touched.treasurerPaternal 
                                && validation.errors.treasurerPaternal ? true : false
                            }
                        />
                        {validation.touched.treasurerPaternal && validation.errors.treasurerPaternal ? (
                            <FormFeedback type="invalid">{validation.errors.treasurerPaternal}</FormFeedback>
                        ) : null}
                        </div>
                        <div className="mb-3">
                          <Label className="control-label">Cargo</Label>
                          <Input
                            id="treasurerPosition"
                            name="treasurerPosition"
                            type="text"
                            className="form-control"
                            placeholder=""
                            onChange={validation.handleChange} 
                            onBlur={validation.handleBlur}
                            value={validation.values.treasurerPosition || ""}
                            invalid={
                                validation.touched.treasurerPosition 
                                && validation.errors.treasurerPosition ? true : false
                            }
                        />
                        {validation.touched.treasurerPosition && validation.errors.treasurerPosition ? (
                            <FormFeedback type="invalid">{validation.errors.treasurerPosition}</FormFeedback>
                        ) : null}
                        </div>
                      </Col>
                      <Col sm="4">
                        <div className="mb-3">
                          <Label className="control-label">Apellido Materno</Label>
                          <Input
                            id="treasurerMaternal"
                            name="treasurerMaternal"
                            type="text"
                            className="form-control"
                            placeholder=""
                            onChange={validation.handleChange} 
                            onBlur={validation.handleBlur}
                            value={validation.values.treasurerMaternal || ""}
                            invalid={
                                validation.touched.treasurerMaternal 
                                && validation.errors.treasurerMaternal ? true : false
                            }
                        />
                        {validation.touched.treasurerMaternal && validation.errors.treasurerMaternal ? (
                            <FormFeedback type="invalid">{validation.errors.treasurerMaternal}</FormFeedback>
                        ) : null}
                        </div>
                        <div className="mb-3">
                          <Label className="control-label">Clave Para Oficios</Label>
                          <Input type="text" name="treasurerCodeKey"
                           
                           
                            
                            onChange={validation.handleChange} 
                            onBlur={validation.handleBlur}
                            value={validation.values.treasurerCodeKey || ""}
                            invalid={
                                validation.touched.treasurerCodeKey 
                                && validation.errors.treasurerCodeKey ? true : false
                            }
                        >
                            </Input>
                        {validation.touched.treasurerCodeKey && validation.errors.treasurerCodeKey ? (
                            <FormFeedback type="invalid">{validation.errors.treasurerCodeKey}</FormFeedback>
                        ) : null}
                        </div>
                      
                      </Col>
                    </Row>
                </CardBody>
                <CardFooter>
                <div className="text-end">
                        <Button type="submit" color="primary" className="btn ">
                            Guardar Cambios
                        </Button>
                        
                    </div>
                    
                </CardFooter>
              </Card>
            
        </Form>
        </>
    )
}

export default FormComissionFinance