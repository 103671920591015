import React, { useState, useEffect } from 'react';
import { useSelector } from "react-redux"
import { Modal, ModalBody, ModalHeader } from "reactstrap"
import PropTypes from "prop-types"
import InvoiceDetailModal from "../../../pages/Requisitions/RequisitionDetailModal"

const PDFViewer = props => {
  const { isOpen, toggle } = props
  const { docUrl } = useSelector(state => ({
    docUrl: state.Requisitions.docUrl,
  }));

  return (
    <Modal
      isOpen={isOpen} size="xl"
      role="dialog"
      autoFocus={true}
      centered={true}

      tabIndex="-1"
      toggle={toggle}
    >
      <div className="modal-content">
        <ModalHeader toggle={toggle}> Documentos </ModalHeader>
        <ModalBody>
      {docUrl ? (
        <iframe
          src={docUrl}
          width="100%"
          height="600px"
          title="PDF Viewer"
        />
      ) : (
        <p>Loading PDF...</p>
      )}
        </ModalBody>
      </div>
    </Modal>
  );
};

PDFViewer.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
}

export default PDFViewer;