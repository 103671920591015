import { call, put, takeEvery, all, fork } from "redux-saga/effects";

// Crypto Redux States
import {
    GET_AREAS,
    GET_CATS,
    GET_CONFIG_INIT,
    SAVE_AREA,
    SAVE_SOURCE_FINANCING,
    UPDATE_AREA,
    UPDATE_COMISSION_FINANCE,
    UPDATE_CONFIG_INI,
    getAreasSuccess,
    getCatsSuccess,
    getConfigInitSuccess,
    saveAreaSuccess,
    saveSourceFinancingSuccess,
    updateComissionFinanceSuccess,
    UPLOAD_IMAGE, uploadImageSuccess, saveNewCityhallSuccess
} from "./actions"
import {
    configInitGet,
    cityHallSave,
    comissionFinanceSave,
    areasGet,
    areaSave,
    areaUpdate,
    getCatsList,
    sourceFinancingSave,
    uploadLetterheadImage
} from "helpers/backend_helper"
import { getCityHallsSuccess, showPreloader } from "store/actions"
import { SHOW_PRELOADER } from "store/layout/actionTypes";
import toastr from "toastr";

function* onGetAreas({ payload: filter }) {
    yield put(showPreloader(true))
    try {
        const response =  yield call(areasGet, filter);
        if(response.success) {
            yield put(getAreasSuccess(response));
        }
    } catch (error) {
        
    } finally {
        yield put(showPreloader(false))
    }
}

function* onSaveArea({ payload: data }) {
    yield put(showPreloader(true))
    try {
        const response =  yield call(areaSave, data);
        if(response.success) {
            yield put(saveAreaSuccess(response));
            toastr.success(response.message)
        } else {
            toastr.error(response.message)
        }
    } catch (error) {
        toastr.error("Ocurrió un error en la operación")
    } finally {
        yield put(showPreloader(false))
    }
}

function* onSaveSourceFinancing({ payload: data }) {
    yield put(showPreloader(true))
    try {
        const response =  yield call(sourceFinancingSave, data);
        if(response.success) {
            yield put(saveSourceFinancingSuccess(response));
            toastr.success(response.message)
        } else {
            toastr.error(response.message)
        }
    } catch (error) {
        toastr.error("Ocurrió un error en la operación")
    } finally {
        yield put(showPreloader(false))
    }
}

function* onUpdateArea({ payload: date }) {
    yield put(showPreloader(true))
    try {
        const response =  yield call(areaUpdate, filter);
        if(response.success) {
            yield put(saveAreaSuccess(response));
        }
    } catch (error) {
        toastr.error("Ocurrió un error en la operación")
    } finally {
        yield put(showPreloader(false))
    }
}

function* getConfigInit({ payload: filter }) {
    yield put(showPreloader(true))
    try {
        const response =  yield call(configInitGet, filter);
        if(response.success) {
            yield put(getConfigInitSuccess(response));
        }
    } catch (error) {

    } finally {
        yield put(showPreloader(false))
    }
}


function* onGetCatsList({ payload: filter }) {

    try {
        const response =  yield call(getCatsList, filter);
        if(response.success) {
            yield put(getCatsSuccess(response));
        }
    } catch (error) {
        toastr.error("Ocurrió un error en la operación")
    }
}

function* onUpdateConfigInit({ payload: dataPost }) {
    yield put(showPreloader(true));
    try {
        const response =  yield call(cityHallSave, dataPost);
        if(response.success) {
            yield put(getCityHallsSuccess(response));
            //yield put(getConfigInitSuccess(response));
            yield put(saveNewCityhallSuccess(response))
            toastr.success(response.message)
        }else {
            toastr.error(response.message)
        }
    } catch (error) {
        toastr.error("Ocurrió un error en la operación")
    }
    finally {
        yield put(showPreloader(false));
    }
}

function* onUpdateComissionFinance({ payload: dataPost }) {
    yield put(showPreloader(true));
    try {
        const response =  yield call(comissionFinanceSave, dataPost);
        if(response.success) {
            yield put(getConfigInitSuccess(response));
            toastr.success(response.message)
        } else {
            toastr.error(response.message)
        }
    } catch (error) {
       
    } finally {
        yield put(showPreloader(false));
    }
}

function* onUploadImage ({payload:data}) {
    try {
        yield put({type:SHOW_PRELOADER,payload: true})
        const response = yield call(uploadLetterheadImage, data)
        if(response.success){
            yield put(uploadImageSuccess(response))
            toastr.success(response.message)
        } else {
            toastr.error(response.message)
        }
    } catch (error) {
        console.error(error)
        toastr.error("Hubo un error al guardar la imagen, intenta nuevamente")
    } finally {
        yield put({type:SHOW_PRELOADER,payload: false})
    }
}

export function* watchConfigInit() {
    yield takeEvery(GET_AREAS, onGetAreas);
    yield takeEvery(SAVE_AREA, onSaveArea);
    yield takeEvery(SAVE_SOURCE_FINANCING, onSaveSourceFinancing);
    yield takeEvery(GET_CATS, onGetCatsList);
    yield takeEvery(UPDATE_AREA, onUpdateArea);
    yield takeEvery(GET_CONFIG_INIT, getConfigInit);
    yield takeEvery(UPDATE_CONFIG_INI,onUpdateConfigInit);
    yield takeEvery(UPDATE_COMISSION_FINANCE, onUpdateComissionFinance)
    yield takeEvery(UPLOAD_IMAGE, onUploadImage)
}

function* configInitSaga() {
    yield all([fork(watchConfigInit)]);
}

export default configInitSaga;
