import { select, call, put, takeEvery, all, fork } from "redux-saga/effects";

// Crypto Redux States
import {
    SAVE_REQUISITION,
    setRequisition,
    setActiveTab,
    GET_DOCUMENT,
    GET_REQUISITON_LIST,
    getRequisitionListSuccess,
    GET_DOCUMENT_ALL, getDocumentAllSuccess
} from "./actions"
import {
    getDocumentAllRequisition,
    getDocumentRequisition,
    getRequisitionList,
    requisitionSave
} from "helpers/backend_helper"
import { showPreloader } from "../../store/layout/actions";
import toastr from "toastr";
import { getInvoicesListSuccess } from "../invoices/actions"

const activeTab = state => state.Requisitions.activeTab;
const passedSteps = state => state.Requisitions.passedSteps;

function* onGetRequisitionList({ payload: filter }) {
    yield put(showPreloader(true));
    try {
        const response =  yield call(getRequisitionList, filter);
        yield put(getRequisitionListSuccess(response));
    } catch (error) {
        
    } finally {
        yield put(showPreloader(false))
    }
}

function* onSaveRequisition ({ payload: data }) {
    yield put(showPreloader(true))
    try {
        const response =  yield call(requisitionSave, data);
        if(response.success) {
            yield put(setRequisition(response.data.requisition));
            yield put(getRequisitionListSuccess(response.data.requisitionList))
            yield put(getInvoicesListSuccess(response.data.invoiceList));
            let activeTabL =  data.step_current;//yield select(activeTab);
            var passedStepsL =  yield select(passedSteps);
            activeTabL = activeTabL + 1
            if (activeTab !== activeTabL) {
                var modifiedSteps = [...passedStepsL, activeTabL]
                if (activeTabL >= 1 && activeTabL <= 5) {
                    yield put(setActiveTab({activeTab:activeTabL,passedSteps:modifiedSteps}))
                }
            }
            toastr.success(response.message)
        } else {
            toastr.error(response.message)
        }
    } catch (error) {
        toastr.error("Hubo un error en la operación, intenta nuevamente")
        console.error(error)
    } finally {
        yield put(showPreloader(false))
    }
}

function* getDocumentDown ({payload: filter}) {
    yield put(showPreloader(true))
    try {
        const doc =  yield call(getDocumentRequisition, filter);
        yield put(getDocumentAllSuccess(doc))
    } catch (error) {
        toastr.error("Hubo un error en la operación, intenta nuevamente")
        console.error(error)
    } finally {
        yield put(showPreloader(false))
    }
}

function* getDocumentAllDown ({payload: filter}) {
    yield put(showPreloader(true))
    try {
        const doc =  yield call(getDocumentAllRequisition, filter);
        yield put(getDocumentAllSuccess(doc))
    } catch (error) {
        toastr.error("Hubo un error en la operación, intenta nuevamente")
        console.error(error)
    } finally {
        yield put(showPreloader(false))
    }
}

export function* watchRequsitionData() {
    yield takeEvery(SAVE_REQUISITION, onSaveRequisition);
    yield takeEvery(GET_DOCUMENT, getDocumentDown);
    yield takeEvery(GET_DOCUMENT_ALL, getDocumentAllDown);
    yield takeEvery(GET_REQUISITON_LIST, onGetRequisitionList)
}

function* requisitionSaga() {
    yield all([fork(watchRequsitionData)]);
}

export default requisitionSaga;
