import React, { useEffect, useState } from "react"
import { Button, Col, Form, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap"
import PropTypes from "prop-types"

import { getCityHalls as onGetCityHalls } from "../../../store/auth/profile/actions"
import { useDispatch, useSelector } from "react-redux"
import { useActiveCityhall } from "../../../context/ActiveCityhallContext";

import toastr from "toastr"

const AdminCityhalls = ({isOpen=false, toggle, toggleNewCityhall, createCityhallShow}) => {

  const dispatch = useDispatch()

  const { activeCityhall, setActiveCityhall } = useActiveCityhall();
  const handleSelectCityhall = (cityhall) => {
    setActiveCityhall(cityhall);
    toastr.success("Municipio Seleccionado")
  };

  const { cityhalls, getCityhallsSuccess, saveNewCityhallSuccess } = useSelector(state => ({
    cityhalls: state.Profile.cityhalls,
    getCityhallsSuccess: state.Profile.getCityhallsSuccess,
    saveNewCityhallSuccess: state.ConfigInit.saveNewCityhallSuccess,
  }));

  const handleGetCityhalls = () => {
    dispatch(onGetCityHalls({}))
  }

  const [cityHallList,setCityHallList] = useState([])
  useEffect(()=> {
    if(isOpen) {
      handleGetCityhalls()
    }
  },[isOpen])

  const [cityhallChecked, setCityhallChecked] = useState(null)
  const handleSetCityhallChecked = (cityhall) => {
    setCityhallChecked(cityhall)
  }

  useEffect(()=> {
    if(getCityhallsSuccess) {
      setCityHallList(cityhalls)
    }
  },[getCityhallsSuccess,cityhalls])

  useEffect(()=> {
    if(saveNewCityhallSuccess && createCityhallShow) {
      toggleNewCityhall()
    }
  },[saveNewCityhallSuccess])

  return (
    <>
      <Modal
        isOpen={isOpen} backdrop="static"
        role="dialog"
        autoFocus={true} size="xl"
        centered={true}
        className="exampleModal"
        tabIndex="-1"
        toggle={toggle}
      >
        <div className="modal-content">
          <Form>
            <ModalBody>
              <Row>
                <Col sm={12} md={12} lg={12} className="text-end mb-3">
                  <Button  color="primary" onClick={()=>toggleNewCityhall()}>
                    <i className="fa fa-plus"></i> Agregar Municipio
                  </Button>
                </Col>
                <Col sm={12} md={12} lg={12} className=" mb-3">

                  <table className="table table-condensed">
                    <thead>
                    <tr>
                      <th className="font-size-16">
                        Municipio en Sesión
                      </th>
                      <th>R.F.C.</th>
                    </tr>
                    </thead>
                    <tbody>
                    {activeCityhall ?
                    <tr>
                      <td>
                        <h5 className="text-success">
                          {activeCityhall?.name}
                        </h5>
                      </td>
                      <td>
                        <h5 className="text-success">
                          {activeCityhall?.rfc}
                        </h5>
                      </td>
                    </tr>
                      :
                      <tr>
                        <td colSpan={2}  className="text-center">
                          <h3 className="text-warning">Selecciona Municipio para poder continuar</h3>
                        </td>
                      </tr>}
                    </tbody>
                  </table>
                </Col>
                <Col sm={12} md={12} lg={12}>
                  <table className="table table-bordered">
                    <thead>
                    <tr>
                      <th>
                        Nombre
                      </th>
                      <th>
                        RFC
                      </th>
                      <th>
                        Opciones
                      </th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                      cityHallList?.map((item,key)=> {
                        return (<tr key={key}>
                          <td>{item.name}</td>
                          <td>{item.rfc}</td>
                          <td>
                            {
                              activeCityhall?.id===item.id ? <span className="badge badge-soft-primary font-size-14">Seleccionado</span>
                                :
                            <div className="form-check form-check-success mb-3">
                              <input type="checkbox"  className="form-check-input"
                                     id={"checkcityhall" + key} onClick={()=>handleSelectCityhall(item)}
                                     checked={item.id===activeCityhall?.id} />
                              <label className="form-check-label" htmlFor={"checkcityhall" + key}>
                                Seleccionar
                              </label>
                            </div>
                            }
                          </td>
                        </tr>)
                      })
                    }
                    {
                      cityHallList?.length===0 ?
                        <tr><td className="text-center" colSpan={2}>No hay municipios para el usuario, crea tu primer Municipio </td></tr>:""
                    }
                    </tbody>
                  </table>
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter>
              <Button disabled={!activeCityhall} onClick={toggle} color="primary">Cerrar</Button>
            </ModalFooter>
          </Form>
        </div>
      </Modal>
    </>
  )
}

AdminCityhalls.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  toggleNewCityhall: PropTypes.func.isRequired,
  createCityhallShow: PropTypes.bool.isRequired,
};


export default AdminCityhalls;