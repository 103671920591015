export const GET_AREAS = "GET_AREAS";
export const GET_AREAS_SUCCESS = "GET_AREAS_SUCCESS";


export const SAVE_AREA = "SAVE_AREA";
export const SAVE_AREA_SUCCESS = "SAVE_AREA_SUCCESS";
export const RESET_SAVE_AREA_SUCCESS = "RESET_SAVE_AREA_SUCCESS";
export const UPDATE_AREA = "UPDATE_AREA";

export const SAVE_SOURCE_FINANCING = "SAVE_SOURCE_FINANCING";
export const SAVE_SOURCE_FINANCING_SUCCESS = "SAVE_SOURCE_FINANCING_SUCCESS";

export const GET_CATS = "GET_CATS";
export const GET_CATS_SUCCESS = "GET_CATS_SUCCESS";

export const GET_CONFIG_INIT = "GET_CONFIG_INIT";
export const GET_CONFIG_INIT_SUCCESS = "GET_CONFIG_INIT_SUCCESS";
export const UPDATE_CONFIG_INI = "UPDATE_CONFIG_INI";
export const SET_CITY_HALL_ACTIVE = "SET_CITY_HALL_ACTIVE";
export const UPDATE_CONFIG_INI_SUCCESS = "UPDATE_CONFIG_INI_SUCCESS";

export const UPDATE_COMISSION_FINANCE = "UPDATE_COMISSION_FINANCE";
export const UPDATE_COMISSION_FINANCE_SUCCESS = "UPDATE_COMISSION_FINANCE_SUCCESS";

export const POST_TRIGGER_POSITIONS = "POST_TRIGGER_POSITIONS";
export const UPLOAD_IMAGE = "UPLOAD_IMAGE";
export const UPLOAD_IMAGE_SUCCESS = "UPLOAD_IMAGE_SUCCESS";
export const SAVE_NEW_CITY_HALL_SUCCESS = "SAVE_NEW_CITY_HALL_SUCCESS";



export const getAreas = filter => ({
    type: GET_AREAS,
    payload: filter
});

export const getAreasSuccess = data => ({
    type: GET_AREAS_SUCCESS,
    payload: data
});

export const saveArea = data => ({
    type: SAVE_AREA,
    payload: data
});

export const saveAreaSuccess = data => ({
    type: SAVE_AREA_SUCCESS,
    payload: data
});

export const saveSourceFinancing = data => ({
    type: SAVE_SOURCE_FINANCING,
    payload: data
});

export const saveSourceFinancingSuccess = data => ({
    type: SAVE_SOURCE_FINANCING_SUCCESS,
    payload: data
});

export const resetSaveAreaSuccess = flag => ({
    type: RESET_SAVE_AREA_SUCCESS,
    payload: flag
});

export const updateArea = data => ({
    type: UPDATE_AREA,
    payload: data
});

export const getCats = filter => ({
    type: GET_CATS,
    payload: filter
});

export const getCatsSuccess = data => ({
    type: GET_CATS_SUCCESS,
    payload: data
});

export const getConfigInit = dataPost => ({
    type: GET_CONFIG_INIT,
    payload: dataPost
});

export const getConfigInitSuccess = data => ({
    type: GET_CONFIG_INIT_SUCCESS,
    payload: data
});


export const updateConfigInit = dataPost => ({
    type: UPDATE_CONFIG_INI,
    payload: dataPost
});

export const setActiveCityhall = data => ({
    type: SET_CITY_HALL_ACTIVE,
    payload: data
});
export const updateConfigInitSuccess = data => ({
    type: UPDATE_CONFIG_INI_SUCCESS,
    payload: data
});

export const postTriggerPositions = dataPost => ({
    type: POST_TRIGGER_POSITIONS,
    payload: dataPost
});

export const updateComissionFinance = dataPost => ({
    type: UPDATE_COMISSION_FINANCE,
    payload: dataPost
});

export const updateComissionFinanceSuccess = data => ({
    type: UPDATE_COMISSION_FINANCE_SUCCESS,
    payload: data
});

export const uploadImage = data => ({
    type: UPLOAD_IMAGE,
    payload:data
})

export const uploadImageSuccess = data => ({
    type: UPLOAD_IMAGE_SUCCESS,
    payload: data,
})
export const saveNewCityhallSuccess = flag => ({
    type: SAVE_NEW_CITY_HALL_SUCCESS,
    payload: flag,
})
