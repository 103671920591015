import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import {
  Button,
  Col,
  Form,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap"

import * as Yup from "yup";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";

import { uploadInvoicesRows as onUploadInvoicesRows } from "store/actions";
import FormWizardReq from "./FormWizardReq";

const GenerateRequisitionModal = props => {
  const { isOpen, toggle, invoicesAdd } = props

  const dispatch = useDispatch()

  return (
    <Modal
      isOpen={isOpen} size="xl"
      role="dialog"
      autoFocus={true}
      centered={true}
     
      tabIndex="-1"
      toggle={toggle}
    >
      <div className="modal-content">
        <ModalHeader toggle={toggle}> Nueva Requisición</ModalHeader>
        <ModalBody>
          <FormWizardReq isOpen={isOpen} invoicesAdd={invoicesAdd}/>
        </ModalBody>
      </div>
    </Modal>
  )
}

GenerateRequisitionModal.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
}

export default GenerateRequisitionModal
