import React, { useEffect, useState } from "react";
import { Button, Card, CardBody, CardTitle, Col, Form, FormFeedback, Input, InputGroup, Label, Row } from "reactstrap";
import { getConfigInit as onGetConfigInit } from "../../store/Config/actions"
import { useDispatch, useSelector } from "react-redux";

const FormStep3 = props => {

  const { validation, catAreas } = props


  const dispatch = useDispatch()

  const  { requisition, comissionFinance } = useSelector (state => ({
    requisition: state.Requisitions.requisition, 
    comissionFinance: state.ConfigInit.comissionFinance
  }));

    const [formRows, setFormRows] = useState([{ id: 1 }]);

   
  

    return (
        <>
            <Form>
                          <Row>
                              <Col lg="4">
                                <div className="mb-3">
                                  
                                </div>
                              </Col>
                              <Col lg="4">
                                <div className="mb-3">
                                  <Label for="basicpill-lastname-input2">
                                    Fecha del Documento [Oficio]
                                  </Label>
                                  <Input
                                    type="date" name="dateReq" id="dateReq"
                                    className="form-control" placeholder=""
                                    onChange={validation.handleChange} 
                                    onBlur={validation.handleBlur}
                                    value={validation.values.dateReq || ""}
                                    invalid={
                                        validation.touched.dateReq && validation.errors.dateReq ? true : false
                                    }
                                />
                                {validation.touched.dateReq && validation.errors.dateReq ? (
                                    <FormFeedback type="invalid">{validation.errors.dateReq}</FormFeedback>
                                ) : null}
                                </div>
                              </Col>
                              <Col lg="4">
                                <div className="mb-3">
                                  <Label for="presikeycode">
                                    Número de Solicitud [del Oficio]
                                  </Label>
                                  <InputGroup>
                                    <div className="input-group-text">{comissionFinance?.president?.code_key}</div>
                                    <input type="text" className="form-control" id="presikeycode" 
                                    disabled={true} placeholder="Automático" />
                                  </InputGroup>

                                </div>
                              </Col>

                            </Row>
                          
                            <Row>
                              <Col lg="12">
                                <div className="mb-3">
                                  <Label for="description2">
                                    Objeto del Requerimiento
                                  </Label>
                                  <Input type="textarea"
                                    id="description2"
                                    className="form-control"
                                    rows="2"
                                    placeholder="" name="description"
                                    disabled={true}
                                    onChange={validation.handleChange} 
                                    onBlur={validation.handleBlur}
                                    value={requisition?.form2?.description || ""}
                                    invalid={
                                        validation.touched.description && validation.errors.description ? true : false
                                    }
                                />
                                {validation.touched.description && validation.errors.description ? (
                                    <FormFeedback type="invalid">{validation.errors.description}</FormFeedback>
                                ) : null}
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col lg="6">
                                <div className="mb-3">
                                  <Label for="numOficioA">
                                    Como se Describe en el Oficio
                                  </Label>
                        
                                  <InputGroup id="numOficioA">
                                  <input className="input-group-text" type="text" value={requisition?.form2?.codeKey}  
                                    disabled={true} placeholder="-" />
                  
                                    <input type="text" className="form-control"  
                                    disabled={true} placeholder="Automático" />
                                  </InputGroup>
                                </div>
                              </Col>
                              <Col lg="6">
                                <div className="mb-3">
                                  <Label for="date3">
                                    De Fecha
                                  </Label>
                                  <Input
                                    type="date" disabled={true} value={requisition?.form2?.dateReq}
                                    className="form-control"
                                    id="date3"
                                    placeholder=""
                                  />
                                </div>
                              </Col>
                            </Row>
                            
                          </Form>
        </>
    )
}

export default FormStep3