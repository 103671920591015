//REGISTER
export const POST_FAKE_REGISTER = "/post-fake-register";

//LOGIN
export const POST_LOGIN = "login";

/**
 *
 * @type {string}
 */
export const POST_PASSWORD_VERIFIED = "/password-verified";
export const SEND_EMAIL_VERIFICATION = "/email/send-verification";
export const GET_DATA_ADIC_ACCOUNT = "/user/data-adic";

export const INVOICE_GET_LIST = "/invoice/get-list"
export const INVOICES_ROWS_UPLOAD = "/invoice/upload"

export const AREAS_GET = "/config/get-areas"
export const AREA_SAVE = "/area/save"
export const AREA_UPDATE = "/area/update"

export const SOURCE_FINANCING_SAVE = "/config/source-financing-save"

export const CATS_LIST_GET = "/cats/get-list"

export const CONFIG_INIT_GET = "/config/get"
export const CONFIG_LETTERHEAD_IMAGE_UPLOAD = "/config/letterhead-save"

export const CONFIG_INIT_SAVE = "/config/city-hall-save"

export const COMISSION_FINANCE_SAVE = "/config/commission-finance-save"

export const TRIGGER_POSITIONS_SAVE = "/config/positions-trigger"

export const GET_REQUISITON_LIST = "/requisition/list"
export const GET_DOCUMENT_DOWN = "/requisition/get-document"
export const GET_DOCUMENT_ALL_DOWN = "/requisition/get-documents"

export const SAVE_REQUISITON = "/requisition/save"

export const GET_CITY_HALLS_BY_USER = "/user/get-city-halls"

