import React, { useEffect, useState } from "react";
import { Button, Card, CardBody, CardTitle, Col, Form, FormFeedback, Input, InputGroup, Label, Row } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { constant } from "lodash";

const FormStep2 = props => {

  const { invoicesList, validation, handleChangeInvoiceItem } = props


  const dispatch = useDispatch()
  const [formRows, setFormRows] = useState([{ id: 1 }]);

  const  { activeTab2, requisition, comissionFinance } = useSelector(state => ({
      activeTab2: state.Requisitions.activeTab,
      requisition: state.Requisitions.requisition, 
      comissionFinance: state.ConfigInit.comissionFinance
    }));

    const [invoicesL,setInvoicesL] = useState(invoicesList)
    const handleChangeItem = (e,target, key2,key1) => {
        handleChangeInvoiceItem(e,target,key2,key1)
    }

    useEffect(()=> {
      setInvoicesL(invoicesList)
    },[invoicesList])
    return (
        <>
            <Form>
                          <Row>
                              <Col lg="4">
                                <div className="mb-3">
                                  
                                </div>
                              </Col>
                              <Col lg="4">
                                <div className="mb-3">
                                  <Label for="basicpill-lastname-input2">
                                    Fecha de la Solicitud [Oficio]
                                  </Label>
                                  <Input
                                    type="date" name="dateReq" id="dateReq"
                                    className="form-control" placeholder=""
                                    onChange={validation.handleChange} 
                                    onBlur={validation.handleBlur}
                                    value={validation.values.dateReq || ""}
                                    invalid={
                                        validation.touched.dateReq && validation.errors.dateReq ? true : false
                                    }
                                />
                                {validation.touched.dateReq && validation.errors.dateReq ? (
                                    <FormFeedback type="invalid">{validation.errors.dateReq}</FormFeedback>
                                ) : null}
                                </div>
                              </Col>
                              <Col lg="4">
                                <div className="mb-3">
                                  <Label for="basicpill-lastname-input2">
                                    Número de Solicitud [del Oficio]
                                  </Label>
                                  <InputGroup>
                                  <input className="input-group-text" type="text" name="codeKey" value={validation.values.codeKey}  
                                    disabled={true} placeholder="Automático" />
                  
                                    <input type="text" className="form-control" id="inlineFormInputGroupUsername" 
                                    disabled={true} placeholder="Automático" />
                                  </InputGroup>

                                </div>
                              </Col>


                            </Row>
                            <Row>
                              <Col lg="6">
                                <div className="mb-3">
                                  <Label for="">
                                    Se Deriva de la Solicitud:
                                  </Label>
                                  <Input
                                    type="text" disabled={true}
                                    className="form-control" value={requisition?.form1?.codeKey+"-"+"[consecutivo]"}
                                    id=""
                                    placeholder=""
                                  />
                                </div>
                              </Col>
                              <Col lg="6">
                                <div className="mb-3">
                                  <Label for="basicpill-lastname-input2">
                                    De Fecha:
                                  </Label>
                                  <Input
                                    type="date" disabled={true} value={requisition?.form1?.dateReq}
                                    className="form-control"
                                    id="basicpill-lastname-input2"
                                    placeholder=""
                                  />
                                </div>
                              </Col>
                            </Row>
                            <Row style={{display:invoicesL?.length<=0?"none":"inherit"}}>
                              <Col lg="12">
                                <div className="mb-3">
                                  <Label for="basicpill-address-input1">
                                    Listado de conceptos
                                  </Label>
                                  <table className="table ">
                                    <thead>
                                        <tr>
                                            <th>Folio de Factura</th>
                                            <th>Proveedor </th>
                                            <th style={{width:"60%"}}>Total</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {invoicesL?.map(
                                      (item,key1) => { return (<>
                                        <tr key={key1}>
                                          <td >{item?.uuid}</td>
                                          <td >{item?.issuer_business_name}</td>
                                          <td >{item?.total}</td>
                                        </tr>
                                        <tr key={key1+100}>
                                          <td colSpan={3}>
                                            <table className="table">
                                              <thead>
                                                <th>Cantidad</th>
                                                <th>Unidad</th>
                                                <th>Concepto</th>
                                              </thead>
                                              {
                                                item?.det?.map((item_det, key2) =>  { return (
                                                  <tr key={key2}>
                                                      <td>
                                                          <Input type="text" value={item_det?.quantity}
                                                          onChange={(e)=>handleChangeItem(e,'quantity',key2,key1)} name="quantity"/>
                                                      </td>
                                                      <td>
                                                        
                                                        <Input type="text" value={item_det?.cat_unit_measure_sat}
                                                        onChange={(e)=>handleChangeItem(e,'unit', key2,key1)} name="cat_unit_measure_sat"/>
                                                      </td>
                                                      <td className="">
                                                        <Input type="text" value={item_det?.description}
                                                        onChange={(e)=>handleChangeItem(e,'desc',key2,key1)} name="description"/>
                                                      </td>
                                                  </tr>
                                                  )})
                                              }
                                            </table>
                                          </td>
                                        </tr>
                                        </>
                      
                                        
                                      ) } ) }
                                    </tbody>
                                    
                                  </table>
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col lg="12">
                                <div className="mb-3">
                                  <Label for="description2">
                                    Objeto del Requerimiento
                                  </Label>
                                  <Input type="textarea"
                                    id="description2"
                                    className="form-control"
                                    rows="2"
                                    placeholder="" name="description"
                                    
                                    onChange={validation.handleChange} 
                                    onBlur={validation.handleBlur}
                                    value={validation.values.description || ""}
                                    invalid={
                                        validation.touched.description && validation.errors.description ? true : false
                                    }
                                />
                                {validation.touched.description && validation.errors.description ? (
                                    <FormFeedback type="invalid">{validation.errors.description}</FormFeedback>
                                ) : null}
                                </div>
                              </Col>
                            </Row>
                            
                          </Form>
        </>
    )
}

export default FormStep2