import React, { useState } from "react";
import { Button, Card, CardBody, CardTitle, Col, Form, FormFeedback, Input, InputGroup, Label, Row } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { Controller } from "swiper";

const FormStep1 = props => {

    const { validation, catAreas } = props

    const { areas } = useSelector(state => ({
      areas: state.ConfigInit.areas,
    }));

    const [applicant,setApplicant] = useState(null)
    const [formRows, setFormRows] = useState ([{ id: 1 }]);
    const [doc1CodeKey,setDoc1CodeKey] = useState(null)
    const onAddFormRow = () => {
        const modifiedRows = [...formRows];
        modifiedRows.push({ id: modifiedRows.length + 1 });
        setFormRows(modifiedRows);
    };

    const onDeleteFormRow = id => {
        if (id !== 1) {
          var modifiedRows = [...formRows];
          modifiedRows = modifiedRows.filter(x => x["id"] !== id);
          setFormRows(modifiedRows);
        }
    };

    const onChangeApplicant=(e) => {
      validation.values["position"]=""
      validation.values["area"]=""
      validation.values["codeKey"]=""
      setApplicant(null);
      setDoc1CodeKey(null)
      validation.values["applicant"]=e.target.value
      const applic=areas.find(i=>i.id==e.target.value);
      if(applic) {
        validation.values["position"]=applic.position
        validation.values["area"]=applic.name
        validation.values["codeKey"]=applic.code_key
        setApplicant(applic);
        setDoc1CodeKey(applic.code_key)
      }

    }

    return (
        <>
            <Form>
            <Row>
                              <Col lg="8">
                                <div className="mb-3">
                                  
                                </div>
                              </Col>
                              <Col lg="4">
                                <div className="mb-3">
                                  <Label for="">
                                    Número de Solicitud [del Oficio]
                                  </Label>
                                  <InputGroup>
                                    <input className="input-group-text" type="text" name="codeKey" value={validation.values.codeKey}  
                                    disabled={true} placeholder="Automático" />
                                  
                                    <input type="text" className="form-control" 
                                    disabled={true} placeholder="Automático" />
                                  </InputGroup>

                                </div>
                              </Col>
                              </Row>
                          <Row>
                              <Col lg="4">
                                <div className="mb-3">
                                  
                                </div>
                              </Col>
                              <Col lg="3">
                                <div className="mb-3">
                                  <Label for="dateReq">
                                    Fecha del Documento [Oficio]
                                  </Label>
                                  <Input
                                    type="date" name="dateReq" id="dateReq"
                                    className="form-control" placeholder=""
                                    onChange={validation.handleChange} 
                                    onBlur={validation.handleBlur}
                                    value={validation.values.dateReq || ""}
                                    invalid={
                                        validation.touched.dateReq && validation.errors.dateReq ? true : false
                                    }
                                />
                                {validation.touched.dateReq && validation.errors.dateReq ? (
                                    <FormFeedback type="invalid">{validation.errors.dateReq}</FormFeedback>
                                ) : null}
                                </div>
                              </Col>
                              <Col lg="5">
                                <div className="mb-3">
                                  <Label for="subject">
                                    Asunto [del Oficio]
                                  </Label>
                                  <Input
                                    type="text"
                                    className="form-control"
                                    id="subject" name="subject"
                                    placeholder=""
                                    onChange={validation.handleChange} 
                                    onBlur={validation.handleBlur}
                                    value={validation.values.subject || ""}
                                    invalid={
                                        validation.touched.subject && validation.errors.subject ? true : false
                                    }
                                />
                                {validation.touched.subject && validation.errors.subject ? (
                                    <FormFeedback type="invalid">{validation.errors.subject}</FormFeedback>
                                ) : null}
                                </div>
                              </Col>


                            </Row>
                            <Row>
                              <Col lg="4">
                                <div className="mb-3">
                                  <Label for="applicant">
                                    Nombre del Solicitante
                                  </Label>
                                  <Input
                                    type="select"
                                    className="form-control select"
                                    id="applicant"
                                    placeholder="Ingresa el nombre completo" 
                                    name="applicant" 
                                    
                                    onChange={(e)=>onChangeApplicant(e)} 
                                    onBlur={validation.handleBlur}
                                    value={validation.values.applicant || ""}
                                    invalid={
                                        validation.touched.applicant && validation.errors.applicant ? true : false
                                    }
                                >
                                  <option value={""}>Elige de la lista</option>
                                  {
                                      areas?.map((item,key)=>{
                                        return(<option key={key} value={item.id}>{item?.titular}</option>)
                                      })
                                  }
                                  </Input>
                                {validation.touched.applicant && validation.errors.applicant ? (
                                    <FormFeedback type="invalid">{validation.errors.applicant}</FormFeedback>
                                ) : null}
                                </div>
                              </Col>
                              <Col lg="4">
                                <div className="mb-3">
                                  <Label for="position">
                                    Cargo que desempeña
                                  </Label>
                                  <Input
                                    type="text" disabled={true}
                                    className="form-control"
                                    id="position"
                                    placeholder="" name="position"
                                    
                                    onChange={validation.handleChange} 
                                    onBlur={validation.handleBlur}
                                    value={validation.values.position || ""}
                                    invalid={
                                        validation.touched.position && validation.errors.position ? true : false
                                    }
                                />
                                {validation.touched.position && validation.errors.position ? (
                                    <FormFeedback type="invalid">{validation.errors.position}</FormFeedback>
                                ) : null}
                                </div>
                              </Col>
                              <Col lg="4">
                                <div className="mb-3">
                                  <Label for="area">
                                    Area
                                  </Label>
                                  <Input
                                    type="text" disabled={true}
                                    className="form-control"
                                    id="area"
                                    placeholder="" name="area"
                                    onChange={validation.onChange} 
                                    onBlur={validation.handleBlur}
                                    value={validation.values.area}
                                    invalid={
                                        validation.touched.area && validation.errors.area ? true : false
                                    }
                                >
                                </Input>
                                {validation.touched.area && validation.errors.area ? (
                                    <FormFeedback type="invalid">{validation.errors.area}</FormFeedback>
                                ) : null}
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col lg="12">
                                <div className="mb-3">
                                  <Label for="description">
                                    Descripción / Objeto de la Solicitud
                                  </Label>
                                  <Input type="textarea"
                                    id="description"
                                    className="form-control"
                                    rows="2"
                                    placeholder="" name="description"
                                    
                                    onChange={validation.handleChange} 
                                    onBlur={validation.handleBlur}
                                    value={validation.values.description || ""}
                                    invalid={
                                        validation.touched.description && validation.errors.description ? true : false
                                    }
                                />
                                {validation.touched.description && validation.errors.description ? (
                                    <FormFeedback type="invalid">{validation.errors.description}</FormFeedback>
                                ) : null}
                                </div>
                              </Col>
                            </Row>
                            <Row style={{display:"none"}}>
                              <Col lg="12">
                                <div className="mb-3">
                                  <Label for="basicpill-address-input1">
                                    Listado de conceptos
                                  </Label>
                                  <table className="table ">
                                    <thead>
                                        <tr>
                                            <th>Cantidad</th>
                                            <th style={{width:"70%"}}>Concepto</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {(formRows || []).map((formRow, key) => (
                                        <tr key={key}>
                                            <td>
                                                <Input type="number" name="name"/>
                                            </td>
                                            <td>
                                                <Input type="text" name="name"/>
                                            </td>
                                            <td className="">
                                               { key!==0 ? <Button type="button"  onClick={() => onDeleteFormRow(formRow.id)} className="btn-warning">Quitar</Button>:"" }     
                                            </td>
                                        </tr>
                                        ))}
                                    </tbody>
                                    <tfoot>
                                    
                                        <tr >
                                            <td colSpan={3} className="text-end">
                                            <input
                                                type="button"
                                                className="btn btn-success mt-3 mt-lg-0"
                                                value="Agregar"
                                                onClick={() => onAddFormRow()}
                                                />    
                                            </td>
                                        </tr>
                                    
                                    </tfoot>
                                  </table>
                                </div>
                              </Col>
                            </Row>
                          </Form>
        </>
    )
}

export default FormStep1