import React from 'react';
import { Badge } from 'reactstrap';


export const AreaRowTr = (item) => {
    return (
        <tr>
            <td>{item.code_key}</td>
            <td>{item.name}</td>
            <td>{item.titular}</td>
            <td>{item.position}</td>
            <td><Badge  color={item.active?"primary":"red"} >{item.active?"Activo":"Baja"}</Badge></td>
        </tr>
    );
};
