import React, { useEffect, useMemo, useState } from "react";
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { ceil, isEmpty } from "lodash";
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import TableContainer from  '../../components/Common/TableContainer';
import * as Yup from "yup";
import { useFormik } from "formik";
import toastr from "toastr";

//import components
import Breadcrumbs from '../../components/Common/Breadcrumb';

import { getRequisitionList as onGetRequisitionList, getDocument  as onGetDocument,
  setRequisition as OnSetRequisition, getDocumentAll as onGetDocuments,
getDocumentAllSuccess as onGetDocumentAllSuccess} from "../../store/requisitions/actions"

import {
  OrderId,
  BillingName,
  Date,
  Total,
  PaymentStatus,
  PaymentMethod
}
  from "./RequisitionCol";

//redux
import { useSelector, useDispatch } from "react-redux";

import {
  Button,
  Col,
  Row,
  Card,
  CardBody,
} from "reactstrap";
import GenerateRequisitionModal from "pages/Invoices/GenerateRequisitionModal";
import PDFViewer from "../../components/Common/PDFViewer/PDFViewer"

function RequisitionList() {

  const dispatch = useDispatch()
  
  const { requisitionList, activeTab } = useSelector(state => ({
    requisitionList: state.Requisitions.requisitionList,
    activeTab: state.Requisitions.activeTab
  }));

  //meta title
  document.title = "Requisiciones | Platform";

  const [modal, setModal] = useState(false);
  const [modal1, setModal1] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [viewDocShow, setViewDocShow] = useState(false);

  const [modalGenerateReqShow, setModalGenerateReqShow] = useState(false);

  const [invoices, setInvoices] = useState([]);
  const [order, setOrder] = useState(null);

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      orderId: (order && order.orderId) || '',
      billingName: (order && order.billingName) || '',
      orderdate: (order && order.orderdate) || '',
      total: (order && order.total) || '',
      paymentStatus: (order && order.paymentStatus) || 'Paid',
      badgeclass: (order && order.badgeclass) || 'success',
      paymentMethod: (order && order.paymentMethod) || 'Mastercard',
    },
    validationSchema: Yup.object({
      orderId: Yup.string()
        .matches(
          /[0-9\.\-\s+\/()]+/,
          "Please Enter Valid Order Id"
        ).required("Please Enter Your Order Id"),
      billingName: Yup.string().required("Please Enter Your Billing Name"),
      orderdate: Yup.string().required("Please Enter Your Order Date"),
      total: Yup.string().matches(
        /[0-9\.\-\s+\/()]+/,
        "Please Enter Valid Amount"
      ).required("Total Amount"),
      paymentStatus: Yup.string().required("Please Enter Your Payment Status"),
      badgeclass: Yup.string().required("Please Enter Your Badge Class"),
      paymentMethod: Yup.string().required("Please Enter Your Payment Method"),
    }),
    onSubmit: (values) => {
      if (isEdit) {
        const updateOrder = {
          id: order ? order.id : 0,
          orderId: values.orderId,
          billingName: values.billingName,
          orderdate: values.orderdate,
          total: values.total,
          paymentStatus: values.paymentStatus,
          paymentMethod: values.paymentMethod,
          badgeclass: values.badgeclass,
        };
        // update order
        dispatch(onUpdateOrder(updateOrder));
        validation.resetForm();
      } else {
        const newOrder = {
          id: Math.floor(Math.random() * (30 - 20)) + 20,
          orderId: values["orderId"],
          billingName: values["billingName"],
          orderdate: values["orderdate"],
          total: values["total"],
          paymentStatus: values["paymentStatus"],
          paymentMethod: values["paymentMethod"],
          badgeclass: values["badgeclass"],
        };
        // save new order
        dispatch(onAddNewOrder(newOrder));
        validation.resetForm();
      }
      toggle();
    },
  });


  const toggleViewModal = () => setModal1(!modal1);
  const toggleGenerateRefModal = () => {
    let modalGenerateReqShowL = !modalGenerateReqShow
    setModalGenerateReqShow(modalGenerateReqShowL)
  };

  const toggleViewDocModal = () => {
    const viewDocShowL = !viewDocShow
    setViewDocShow(viewDocShowL);
    if(!viewDocShowL) {
      dispatch(onGetDocumentAllSuccess(null))
    }
  }

  const toggle = () => {
    if (modal) {
      setModal(false);
      setOrder(null);
    } else {
      setModal(true);
    }
  };


  const [invoicesAdd, setInvoicesAdd] = useState([]);
    const handleGetDocumentDownload = (data,e) => {
        dispatch(onGetDocument(data))
      toggleViewDocModal()
    }
    const handleContinueRequisition = (requisitionL,e) => {
        dispatch(OnSetRequisition(requisitionL))
        setModalGenerateReqShow(!modalGenerateReqShow)
    }

  const handleGetDocumentAllDownload = (req) => {
    dispatch(onGetDocuments(req))
    toggleViewDocModal()
  }

  const columns = useMemo(
    () => [

      {
        Header: 'No.',
        accessor: 'id',
        width: '150px',
        style: {
          textAlign: "center",
          width: "10%",
          background: "#0000",
        },
        filterable: true,
        Cell: (cellProps) => {
          return <OrderId {...cellProps} />;
        }
      },
      {
        Header: 'Fecha de Creación',
        accessor: 'created_at_f',
        filterable: true,
        Cell: (cellProps) => {
          return <BillingName {...cellProps} />;
        }
      },
      {
        Header: 'Paso Completado',
        accessor: 'step_current',
        filterable: true,
        Cell: (cellProps) => {
            return <BillingName {...cellProps} />;
        }
      },
      {
        Header: 'Status',
        accessor: 'status',
        filterable: true,
        Cell: (cellProps) => {
            return <PaymentStatus {...cellProps} />;
        }
      },
      {
        Header: 'Doc 1',
        accessor: 'action',
        style: {
            textAlign: "center",
            background: "#0000",
          },
        disableFilters: true,
        Cell: (cellProps) => {
          const reqData = cellProps.row.original;
          {
          return (reqData?.det?.length>0 && reqData?.det[0]?.id ?
            
                <div className="d-flex gap-3">
                    <Button onClick={(e)=>handleGetDocumentDownload(reqData?.det[0], e)} >
                        <i className="fas fa-eye"></i>
                    </Button>              
                </div>:"")
            }
        }
      },
      {
        Header: 'Doc 2',
        accessor: 'action2',
        disableFilters: true,
        Cell: (cellProps) => {
          const reqData = cellProps.row.original;
          {
          return (reqData?.det?.length>1 && reqData?.det[1]?.id ?
            
                <div className="d-flex gap-3">
                    <Button onClick={(e)=>handleGetDocumentDownload(reqData?.det[1], e)} >
                        <i className="fas fa-eye"></i>
                    </Button>              
                </div>:<Button color="primary" onClick={(e)=>handleContinueRequisition(reqData, e)} >
                        <i className="fas fa-angle-double-right"></i> 
                    </Button>)
            }
        }
      },
      {
        Header: 'Doc 3',
        accessor: 'action3',
        disableFilters: true,
        Cell: (cellProps) => {
          const reqData = cellProps.row.original;
          {
          return (reqData?.det?.length>2 && reqData?.det[2]?.id ?
            
                <div className="d-flex gap-3">
                    <Button onClick={(e)=>handleGetDocumentDownload(reqData?.det[2], e)} >
                        <i className="fas fa-eye"></i>
                    </Button>              
                </div>:reqData?.det?.length==2 ? <Button color="primary" onClick={(e)=>handleContinueRequisition(reqData, e)} >
                <i className="fas fa-angle-double-right"></i> 
                    </Button>:"")
            }
        }
      },
      {
        Header: 'Doc 4',
        accessor: 'action4',
        disableFilters: true,
        Cell: (cellProps) => {
          const reqData = cellProps.row.original;
          {
          return (reqData?.det?.length>3 && reqData?.det[3]?.id ?
            
                <div className="d-flex gap-3">
                    <Button onClick={(e)=>handleGetDocumentDownload(reqData?.det[3], e)} >
                        <i className="fas fa-eye"></i>
                    </Button>              
                </div>: reqData?.det?.length==3 ?<Button color="primary" onClick={(e)=>handleContinueRequisition(reqData, e)} >
                <i className="fas fa-angle-double-right"></i> 
                    </Button>:"")
            }
        }
      },{
        Header: 'Todo',
        accessor: 'action5',
        disableFilters: true,
        Cell: (cellProps) => {
          const reqData = cellProps.row.original;
          {
            return (reqData?.status==="Completo" ?
              <div className="d-flex gap-3">
                <Button color="primary" onClick={(e)=>handleGetDocumentAllDownload(reqData)} >
                  <i className="fas fa-eye"></i>
                </Button>
              </div>:"")
          }
        }
      },
    ],
    []
  );

  useEffect(()=> {
    dispatch(onGetRequisitionList());
  },[]);

  useEffect(()=> {
    if(activeTab===5) {
      toggleGenerateRefModal()
    }
  }, [activeTab])
  return (
    <React.Fragment>
     
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Listado" breadcrumbItem="Requisisiones" />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <TableContainer
                    columns={columns}
                    data={requisitionList}
                    isGlobalFilter={true}
                    isAddOption={true}
                    handleOptionClick={toggleGenerateRefModal}
                    labelOption={"Generar Requisición"}
                    customPageSize={10}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
          <PDFViewer  isOpen={viewDocShow} toggle={toggleViewDocModal}/>

          <GenerateRequisitionModal invoicesAdd={invoicesAdd} isOpen={modalGenerateReqShow} toggle={toggleGenerateRefModal} />
        </div>
      </div>
    </React.Fragment>
  );
}
RequisitionList.propTypes = {
  preGlobalFilteredRows: PropTypes.any,

};


export default RequisitionList;