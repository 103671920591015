import React, { useState } from "react";
import { Button, Card, CardBody, CardFooter, CardTitle, Col, Form, FormFeedback, Input, Label, Row } from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";

import { uploadImage as onUploadImage } from "../../store/Config/actions"

const FormLetterheadConf = props => {

    const dispatch = useDispatch()

    const { cityHall, period } = useSelector(state => ({
        cityHall: state.ConfigInit.cityHall,
        period: state.ConfigInit.period
    }));

  const [image, setImage] = useState("");
  const handleImageUpload  = async (e) => {
    try {
      setImage(e.target.files[0])
    } catch (error) {
      console.log(error);
    }

  }
  const uploadFile = async () => {
    const formData = new FormData()
    formData.append("file", image,'letterhead')
    try {
      dispatch(onUploadImage(formData))

    } catch (err) {
      toastr.error("Hubo un error al subir la imagen, intenta nuevamente", "Plataforma Guelaguetza")
    }
  };

    return (
        <>
        <Form onSubmit={(e)=>{
            e.preventDefault();
            validation.handleSubmit();
        }}>
            <Card>
                <CardBody>
                  <Form>
                    <Row>
                      <Col className="position-relative" lg={7}>
                        <img className="w-100" src={cityHall?.letterhead_url_public}/>
                      </Col>
                        <Col sm="5">
                          <Card>
                            <CardBody>
                            <Row>
                              <Col sm="12">
                                <div className="mb-3">
                                  <label className="mb-3 mt-3"> Sube una imagen desde tu equipo </label>
                                  <Input className="form-control form-control-lg" accept="image/*"
                                         onChange={handleImageUpload}
                                         id="formFileLg" type="file" />
                                </div>
                              </Col>
                            </Row>
                            </CardBody>
                          <CardFooter className="text-end">
                            <button className="btn btn-outline-danger" type="button"
                                    onClick={uploadFile}> Enviar imagen
                            </button>
                          </CardFooter>
                          </Card>
                        </Col>
                    </Row>
                  </Form>
                </CardBody>
            </Card>
        </Form>
        </>
    )
}

export default FormLetterheadConf