import { takeEvery, fork, put, all, call } from "redux-saga/effects"

// Login Redux States
import { EDIT_PROFILE } from "./actionTypes"
import { profileSuccess, profileError, GET_CITY_HALLS, getCityHallsSuccess } from "./actions"
import { showPreloader } from "../../layout/actions"
import { getCityhalls, getInvoiceList, invoicesRowsUp } from "../../../helpers/backend_helper"
import { getInvoicesListSuccess, uploadInvoicesRowsSuccess } from "../../invoices/actions"
import toastr from "toastr"


function* editProfile({ payload: { user } }) {
  try {
    if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
      /*const response = yield call(postJwtProfile, "/post-jwt-profile", {
        username: user.username,
        idx: user.idx,
      })
      yield put(profileSuccess(response))*/
    } else if (process.env.REACT_APP_DEFAULTAUTH === "genuine") {
      /*const response = yield call(postFakeProfile, {
        username: user.username,
        idx: user.idx,
      })
      yield put(profileSuccess(response))*/
    }
  } catch (error) {
    yield put(profileError(error))
  }
}
function* onGetCityhalls ({ payload: filter }) {
  yield put(showPreloader(true))
  try {
    const response =  yield call(getCityhalls, filter);
    if(response.success) {
      yield put(getCityHallsSuccess(response));
    } else {
      toastr.error(response.message)
    }
  } catch (error) {
    toastr.error("Hubo un error en la operación, intenta nuevamente")
  } finally {
    yield put(showPreloader(false))
  }
}

export function* watchProfile() {
  yield takeEvery(EDIT_PROFILE, editProfile)
  yield takeEvery(GET_CITY_HALLS, onGetCityhalls)
}

function* ProfileSaga() {
  yield all([fork(watchProfile)])
}

export default ProfileSaga
