import React, { useState } from "react";
import { Button, Card, CardBody, CardTitle, Col, Form, FormFeedback, Input, Label, Row } from "reactstrap";
import { useSelector } from "react-redux";

const FormStep4 = props => {

  const { validation, sourceFinancingList } = props


    const  { catsList, requisition } = useSelector (state => ({
      catsList: state.ConfigInit.catsList,
      requisition: state.Requisitions.requisition
    }));


    return (
        <>
            <Form>
                          <Row>
                              <Col lg="4">
                                <div className="mb-3">
                                  
                                </div>
                              </Col>
                              <Col lg="4">
                                
                              </Col>
                              <Col lg="4">
                              <div className="mb-3">
                                  <Label for="dateReq">
                                    Fecha del Documento [Oficio]
                                  </Label>
                                  <Input
                                    type="date" name="dateReq" id="dateReq"
                                    className="form-control" placeholder=""
                                    onChange={validation.handleChange} 
                                    onBlur={validation.handleBlur}
                                    value={validation.values.dateReq || ""}
                                    invalid={
                                        validation.touched.dateReq && validation.errors.dateReq ? true : false
                                    }
                                />
                                {validation.touched.dateReq && validation.errors.dateReq ? (
                                    <FormFeedback type="invalid">{validation.errors.dateReq}</FormFeedback>
                                ) : null}
                                </div>
                              </Col>
                            </Row>
              {
                requisition?.form1?.invoicesAdd?.length<=0 ?
                            <Row>
                              <Col lg="12">
                                <div className="mb-3">
                                  <Label for="textIntro1">
                                    Edición de texto
                                  </Label>
                                  <div className="input-group mb-3">
                                    <Label className="input-group-text" htmlFor="textIntro1">...Derivado de las necesidades
                                      y peticiones de esta administración,
                                      solicito el pago de </Label>
                                    <Input
                                      type="text" name="textIntro1" id="textIntro1"
                                      className="form-control" placeholder=""
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={validation.values.textIntro1 || ""}
                                      invalid={
                                        validation.touched.textIntro1 && validation.errors.textIntro1 ? true : false
                                      }
                                    />
                                    {validation.touched.textIntro1 && validation.errors.textIntro1 ? (
                                      <FormFeedback type="invalid">{validation.errors.textIntro1}</FormFeedback>
                                    ) : null}
                                  </div>
                                  <div className="input-group mb-3">
                                    <Label className="input-group-text" htmlFor="textIntro2"> a nombre de  </Label>
                                    <Input
                                      type="text" name="textIntro2" id="textIntro2"
                                      className="form-control" placeholder=""
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={validation.values.textIntro2 || ""}
                                      invalid={
                                        validation.touched.textIntro2 && validation.errors.textIntro2 ? true : false
                                      }
                                    />
                                    {validation.touched.textIntro2 && validation.errors.textIntro2 ? (
                                      <FormFeedback type="invalid">{validation.errors.textIntro2}</FormFeedback>
                                    ) : null}
                                  </div>
                                </div>
                              </Col>
                              <Col lg="6">
                                <div className="mb-3">
                                  <Label for="textIntro3">
                                   Ingresa el total a pagar
                                  </Label>
                                  <div className="input-group mb-3">
                                    <Label className="input-group-text" htmlFor="textIntro3">...Importe de </Label>
                                    <Input
                                      type="text" name="amountTotal" id="textIntro3"
                                      className="form-control" placeholder="999.99"
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={validation.values.amountTotal || ""}
                                      invalid={
                                        validation.touched.amountTotal && validation.errors.amountTotal ? true : false
                                      }
                                    />
                                    {validation.touched.amountTotal && validation.errors.amountTotal ? (
                                      <FormFeedback type="invalid">{validation.errors.amountTotal}</FormFeedback>
                                    ) : null}
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          :""}
              <Row>
                <Col lg="12">
                  <div className="mb-3">
                    <table className="table " style={{display:requisition?.form1?.invoicesAdd?.length<=0?"none":"inherit"}}>
                      <thead>
                      <tr>
                        <th>...Derivado de las necesidades y peticiones de esta administración, <br/>solicito el pago de {validation.values.textIntro1}</th>
                        <th>{validation.values.textIntro2}</th>

                      </tr>
                      </thead>
                      <tbody>
                      {requisition?.form1?.invoicesAdd?.map((invoice, key) => (
                        <tr key={key}>
                          <td>
                            {invoice?.uuid}
                          </td>
                          <td>
                            {invoice?.issuer_business_name}
                          </td>

                        </tr>
                      ))}
                      </tbody>

                    </table>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg="4">
                  <div className="mb-3">
                    <Label for="waypay">
                      Forma de Págo
                    </Label>
                    <Input
                      type="select" name="wayPay"
                      className="form-control"
                      id="waypay"
                      placeholder=""
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.wayPay || ""}
                      invalid={
                        validation.touched.wayPay && validation.errors.wayPay ? true : false
                      }
                    >
                      <option value={""}>Elige...</option>
                      {
                        catsList?.catWayPay && catsList?.catWayPay.map((item, key )=>{return (
                          <option key={key} value={item.id}>{item.key_sat}-{item.description}</option>)})
                      }
                    </Input>


                    {validation.touched.wayPay && validation.errors.wayPay ? (
                      <FormFeedback type="invalid">{validation.errors.wayPay}</FormFeedback>
                    ) : null}
                  </div>
                </Col>
                {
                  validation.values.wayPay==="2" ?
                    <Col lg="4">
                      <div className="mb-3">
                        <Label for="noCheck">
                          No. de Cheque
                        </Label>
                        <Input
                          type="text" name="noCheck"
                          className="form-control"
                          id="noCheck"
                          placeholder=""
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.noCheck || ""}
                          invalid={
                            !!(validation.touched.noCheck && validation.errors.noCheck)
                          }
                        />
                        {validation.touched.noCheck && validation.errors.noCheck ? (
                          <FormFeedback type="invalid">{validation.errors.noCheck}</FormFeedback>
                        ) : null}
                      </div>
                    </Col> : <Col lg="4">
                      <div className="mb-3">
                        {validation.values.wayPay}
                      </div>
                    </Col>
                }
                <Col lg="4">
                  <div className="mb-3">
                    <Label for="sourceFinancing">
                      Fuente de Financiamiento
                    </Label>
                    <Input
                      type="select"
                      className="form-control" name="sourceFinancing"
                      id="sourceFinancing"
                      placeholder=""
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.sourceFinancing || ""}
                      invalid={
                        validation.touched.sourceFinancing && validation.errors.sourceFinancing ? true : false
                      }
                    >
                      <option value={""}>Elige...</option>
                      {
                                      sourceFinancingList?.map((item,key)=> {
                                        return(<option key={key} value={item.id}>{item.name}</option>)
                                      }
                                      )
                                    }
                                    </Input>
                                    {validation.touched.sourceFinancing && validation.errors.sourceFinancing ? (
                                        <FormFeedback type="invalid">{validation.errors.sourceFinancing}</FormFeedback>
                                    ) : null}
                                </div>
                              </Col>
              </Row>
                          
                            <Row>
                              <Col lg="12">
                                <div className="mb-3">
                                  <Label for="basicpill-address-input1">
                                    Objeto del Requerimiento
                                  </Label>
                                  <Input type="textarea"
                                    id="description2"
                                    className="form-control"
                                    rows="2"
                                    placeholder="" name="description"
                                    disabled={true}
                                    onChange={validation.handleChange} 
                                    onBlur={validation.handleBlur}
                                    value={requisition?.form2?.description || ""}
                                    invalid={
                                        validation.touched.description && validation.errors.description ? true : false
                                    }
                                />
                                {validation.touched.description && validation.errors.description ? (
                                    <FormFeedback type="invalid">{validation.errors.description}</FormFeedback>
                                ) : null}
                                </div>
                              </Col>
                            </Row>
                            <Row style={{display:"none"}}>
                              <Col lg="6">
                                <div className="mb-3">
                                  <Label for="basicpill-firstname-input1">
                                    Derivado de la Solicitud
                                  </Label>
                                  <Input
                                    type="text"
                                    className="form-control"
                                    id="basicpill-firstname-input1"
                                    placeholder="Enter Your First Name"
                                  />
                                </div>
                              </Col>
                              <Col lg="6">
                                <div className="mb-3">
                                  <Label for="basicpill-lastname-input2">
                                    De Fecha
                                  </Label>
                                  <Input
                                    type="text"
                                    className="form-control"
                                    id="basicpill-lastname-input2"
                                    placeholder="Enter Your Last Name"
                                  />
                                </div>
                              </Col>
                            </Row>
                            
                          </Form>
        </>
    )
}

export default FormStep4