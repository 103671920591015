import React, { useEffect, useState } from "react"
import { Button, Col, Form, Modal, ModalBody, ModalHeader, Row } from "reactstrap"
import PropTypes from "prop-types"

import { getCityHalls as onGetCityHalls } from "../../../store/auth/profile/actions"
import { useDispatch, useSelector } from "react-redux"
import FormCityhallConf from "../FormCityHallConf"

const CreateCityhallModal = ({isOpen=false, toggle}) => {

  const dispatch = useDispatch()

  return (
    <>
      <Modal
        isOpen={isOpen}
        role="dialog"
        autoFocus={true} size="xl"
        centered={true}
        className="exampleModal"
        tabIndex="-1"
        toggle={toggle}
      >
        <div className="modal-content">
            <ModalHeader toggle={toggle}>Crear Municipio</ModalHeader>
            <ModalBody>
              <FormCityhallConf />
            </ModalBody>
        </div>
      </Modal>
    </>
  )
}

CreateCityhallModal.propTypes = {
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
};


export default CreateCityhallModal;