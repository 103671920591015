import { del, get, post, postAndDownload, postAndGetPdf, put, upload } from "./api_helper"
import * as url from "./url_helper";
import { CONFIG_LETTERHEAD_IMAGE_UPLOAD, GET_CITY_HALLS_BY_USER } from "./url_helper"

// Gets the logged-in user data from local session
const getLoggedInUser = () => {
  const user = sessionStorage.getItem("user");
  if (user) return JSON.parse(user);
  return null;
};

//is user is logged in
const isUserAuthenticated = () => {
    return getLoggedInUser() !== null;
};

// Login Method
const postLogin = data => post(url.POST_LOGIN, data);
const sendEmailVerification = data => post(url.SEND_EMAIL_VERIFICATION, data);

export const getInvoiceList = data => upload(url.INVOICE_GET_LIST, data)
export const invoicesRowsUp = data => upload(url.INVOICES_ROWS_UPLOAD, data)

export const areasGet = data => post(url.AREAS_GET, data)
export const areaSave = data => post(url.AREA_SAVE, data)
export const areaUpdate = data => post(url.AREA_UPDATE, data)

export const sourceFinancingSave = data => post(url.SOURCE_FINANCING_SAVE, data)

export const getCatsList = data => post(url.CATS_LIST_GET, data)

export const configInitGet = data => post(url.CONFIG_INIT_GET, data)
export const cityHallSave = data => post(url.CONFIG_INIT_SAVE, data)
export const triggerPositionsPost = data => post(url.TRIGGER_POSITIONS_SAVE,data)

export const comissionFinanceSave = data => post(url.COMISSION_FINANCE_SAVE, data)

export const getDocumentRequisition = filter => postAndGetPdf(url.GET_DOCUMENT_DOWN, filter)
export const getDocumentAllRequisition = filter => postAndGetPdf(url.GET_DOCUMENT_ALL_DOWN, filter)
export const getRequisitionList = filter => post(url.GET_REQUISITON_LIST, filter)
export const requisitionSave = data => post(url.SAVE_REQUISITON, data)

export const uploadLetterheadImage = image => upload(url.CONFIG_LETTERHEAD_IMAGE_UPLOAD, image);

export const getCityhalls = filter => post(url.GET_CITY_HALLS_BY_USER, filter)


export {
    getLoggedInUser,
    isUserAuthenticated,
    postLogin,
    sendEmailVerification,
  };
  