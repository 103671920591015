import { call, put, takeEvery, all, fork } from "redux-saga/effects";

// Crypto Redux States
import { GET_INVOICE_LIST, UPLOAD_INVOICES_ROWS, getInvoicesListSuccess, uploadInvoicesRowsSuccess } from "./actions";
import { invoicesRowsUp, getInvoiceList } from "helpers/backend_helper";
import { showPreloader } from "../../store/layout/actions";
import { SHOW_PRELOADER } from "store/layout/actionTypes";
import toastr from "toastr";

function* getInvoicesData({ payload: filter }) {
    yield put(showPreloader(true));
    try {
        const response =  yield call(getInvoiceList, filter);
        yield put(getInvoicesListSuccess(response));
    } catch (error) {
        
    } finally {
        yield put(showPreloader(false))
    }
}


function* uploadInvoiceRowsFile({ payload: file }) {
    yield put(showPreloader(true))
    try {
        const response =  yield call(invoicesRowsUp, file);
        yield put(uploadInvoicesRowsSuccess(response.success));
        if(response.success) {
            yield put(getInvoicesListSuccess(response));
            toastr.success(response.message)
        } else {
            toastr.error(response.message)
        }
    } catch (error) {
        toastr.error("Hubo un error en la operación, intenta nuevamente")
    } finally {
        yield put(showPreloader(false))
    }
}


export function* watchGetInvoicessData() {
    yield takeEvery(GET_INVOICE_LIST, getInvoicesData);
    yield takeEvery(UPLOAD_INVOICES_ROWS,uploadInvoiceRowsFile)
}

function* invoicesSaga() {
    yield all([fork(watchGetInvoicessData)]);
}

export default invoicesSaga;
