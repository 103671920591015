export const UPLOAD_INVOICES_ROWS = "UPLOAD_INVOICES_ROWS";
export const UPLOAD_INVOICES_ROWS_SUCCESS = "UPLOAD_INVOICES_ROWS_SUCCESS";
export const GET_INVOICE_LIST = "GET_INVOICE_LIST";
export const GET_INVOICE_LIST_SUCCESS = "GET_INVOICE_LIST_SUCCESS";


export const uploadInvoicesRows = file => ({
    type: UPLOAD_INVOICES_ROWS,
    payload: file
});


export const getInvoicesList = data => ({
    type: GET_INVOICE_LIST,
    payload: data
});

export const getInvoicesListSuccess = data => ({
    type: GET_INVOICE_LIST_SUCCESS,
    payload: data
});

export const uploadInvoicesRowsSuccess = data => ({
    type: UPLOAD_INVOICES_ROWS_SUCCESS,
    payload: data
});