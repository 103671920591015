import { all, fork } from "redux-saga/effects";

//public
import AuthSaga from "./auth/login/saga";
import LayoutSaga from "./layout/saga";
import invoicesSaga from "./invoices/saga";
import configInitSaga from "./Config/saga";
import requisitionSaga from "./requisitions/saga"
import ProfileSaga from "./auth/profile/saga"

export default function* rootSaga() {
  yield all([
    fork(AuthSaga),
    fork(LayoutSaga),
    fork(invoicesSaga),
    fork(configInitSaga),
    fork(ProfileSaga),
    fork(requisitionSaga)
  ]);
}
