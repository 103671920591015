import { PROFILE_ERROR, PROFILE_SUCCESS, EDIT_PROFILE, RESET_PROFILE_FLAG } from "./actionTypes";
import { GET_CITY_HALLS_SUCCESS } from "./actions"

const initialState = {
  error: "",
  success: "",
  cityhalls:[],
  getCityhallsSuccess:false
};

const Profile = (state = initialState, action) => {
  switch (action.type) {
    case EDIT_PROFILE:
      state = { ...state };
      break;
    case PROFILE_SUCCESS:
      state = { ...state, success: action.payload };
      break;
    case PROFILE_ERROR:
      state = { ...state, error: action.payload };
      break;
    case RESET_PROFILE_FLAG:
      state = { ...state, success: null };
      break;
    case GET_CITY_HALLS_SUCCESS:
      return  { ...state, cityhalls: action.payload.data, getCityhallsSuccess: action.payload.success }
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default Profile;
