import {
    GET_INVOICE_LIST_SUCCESS,
    UPLOAD_INVOICES_ROWS_SUCCESS,
} from "./actions";

const INIT_STATE = {
    invoiceList: [],
    uploadInvoicesSuccess:false
};

const Invoices = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_INVOICE_LIST_SUCCESS:
            return {
                ...state,
                invoiceList: action.payload.data,
              }
        case UPLOAD_INVOICES_ROWS_SUCCESS:
            return {
                ...state,
                uploadInvoicesSuccess: action.payload,
              }
        default:
            return state;
    }
}


export default Invoices;