import React from "react"
import { Navigate } from "react-router-dom"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"

// Dashboard
import Dashboard from "../pages/Dashboard/index"
import ConfigInit from "pages/ConfigInit/Index"
import Invoices from "pages/Invoices"
import RequisitionList from "pages/Requisitions/RequisitionList"

const authProtectedRoutes = [
  { path: "/dashboard", component: <Dashboard/> },
  { path: "/config", component: <ConfigInit/> },
  { path: "/invoices", component: <Invoices/> },
  { path: "/requisitions", component: <RequisitionList/> },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
   {
    path: "/",
    exact: true,
    component: < Navigate to="/config" />,
  }
]

const publicRoutes = [
  { path: "/logout", component: <Logout /> },
  { path: "/login", component: <Login /> },
]

export { authProtectedRoutes, publicRoutes }

