import { combineReducers } from "redux";

// Front
import Layout from "./layout/reducer";
// Authentication
import Login from "./auth/login/reducer";
import Profile from "./auth/profile/reducer";

import Invoices from "./invoices/reducer";
import Requisitions from "./requisitions/reducer";
import ConfigInit from "./Config/reducer";

const rootReducer = combineReducers({
  Layout,
  Login,
  Profile,
  Invoices,
  Requisitions,
  ConfigInit
});

export default rootReducer;
