import {
  GET_AREAS_SUCCESS,
  GET_CATS_SUCCESS,
  GET_CONFIG_INIT_SUCCESS,
  RESET_SAVE_AREA_SUCCESS,
  SAVE_AREA_SUCCESS, SAVE_NEW_CITY_HALL_SUCCESS,
  SAVE_SOURCE_FINANCING_SUCCESS, SET_CITY_HALL_ACTIVE,
  UPDATE_COMISSION_FINANCE_SUCCESS,
  UPDATE_CONFIG_INI_SUCCESS, UPLOAD_IMAGE_SUCCESS
} from "./actions"

const INIT_STATE = {
    cityHall: null,
    comissionFinance:null,
    period:null,
    areas:[],
    saveAreaSuccess:false,
    saveNewCityhallSuccess:false,
    catsList:[],
    sourceFinancingList:[],
    imageUploadSuccess:false,
    setCityhallActiveSuccess:false,
};

const ConfigInit = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_AREAS_SUCCESS:
            return {
                ...state,
                areas:action.payload.data
              }
        case SAVE_AREA_SUCCESS:
            return {
                ...state,
                areas:action.payload.data,
                saveAreaSuccess:true
              }
         case SAVE_SOURCE_FINANCING_SUCCESS:
            return {
                ...state,
                sourceFinancingList:action.payload.data,
              }
         case GET_CATS_SUCCESS:
            return {
                ...state,
                catsList:action.payload.data,
                
              }
        case RESET_SAVE_AREA_SUCCESS:
            return {
                ...state,
                saveAreaSuccess:action.payload
              }
        case GET_CONFIG_INIT_SUCCESS:
            return {
                ...state,
                cityHall: action.payload.data.cityHall,
                comissionFinance: action.payload.data.comissionFinance,
                period: action.payload.data.period!==null ? action.payload.data.period?.value?.split("|"):null,
                sourceFinancingList: action.payload.data.sourceFinancing
              }
         case UPDATE_CONFIG_INI_SUCCESS:
            return {
                ...state,
                cityHall: action.payload.data.cityHall,
              }
        case UPDATE_COMISSION_FINANCE_SUCCESS:
            return {
                ...state,
                comissionFinance: action.payload.data.comissionFinance,
              }
      case UPLOAD_IMAGE_SUCCESS:
        return {
          ...state,
          imageUploadSuccess:action.payload.success,
          cityHall: action.payload.data.cityHall,
        }
      case SAVE_NEW_CITY_HALL_SUCCESS:
        return {
          ...state,
          saveNewCityhallSuccess: action.payload.success,
        }
      case SET_CITY_HALL_ACTIVE:
        localStorage.setItem("cityhall", JSON.stringify(action.payload));
        return {
          ...state,
          setCityhallActiveSuccess: true
        }
        default:
            return state;
    }
}


export default ConfigInit;