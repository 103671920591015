import React from "react";
import ReactDOM from 'react-dom/client';
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter } from "react-router-dom";
import "./i18n";
import { Provider } from "react-redux";

import store from "./store";
import { ActiveCityhallProvider } from "./context/ActiveCityhallContext"

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <Provider store={store}>
      <React.Fragment>
        <BrowserRouter>
          <ActiveCityhallProvider>
            <App />
          </ActiveCityhallProvider>s
        </BrowserRouter>
      </React.Fragment>
    </Provider>
);

serviceWorker.unregister()
