import { PROFILE_ERROR, PROFILE_SUCCESS, EDIT_PROFILE, RESET_PROFILE_FLAG } from "./actionTypes"
import { GET_REQUISITON_LIST_SUCCESS, SAVE_REQUISITION } from "../../requisitions/actions"

export const GET_CITY_HALLS = "GET_CITY_HALLS"
export const GET_CITY_HALLS_SUCCESS = "GET_CITY_HALLS_SUCCESS"

export const editProfile = user => {
  return {
    type: EDIT_PROFILE,
    payload: { user },
  }
}

export const profileSuccess = msg => {
  return {
    type: PROFILE_SUCCESS,
    payload: msg,
  }
}

export const profileError = error => {
  return {
    type: PROFILE_ERROR,
    payload: error,
  }
}

export const resetProfileFlag = error => {
  return {
    type: RESET_PROFILE_FLAG,
  }
}

export const getCityHalls = filter => ({
  type: GET_CITY_HALLS,
  payload:filter
})

export const getCityHallsSuccess = data => ({
  type: GET_CITY_HALLS_SUCCESS,
  payload: data
});
