import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Col,
  Container,
  Form,
  Input,
  Label,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";

import Dropzone from "react-dropzone";
import classnames from "classnames";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import FormCityhallConf from "./FormCityHallConf";
import { useDispatch, useSelector } from "react-redux";

import {getConfigInit as onGetConfigInit, getAreas as onGetAreas} from "../../store/Config/actions"
import FormComissionFinance from "./FormComissionFinance";
import FormSourceFinancing from "./FormSourceFinancing";
import CatAreas from "./Areas/CatAreas";
import FormLetterheadConf from "./FormLetterheadConf"

const ConfigInit = () => {
  
  const dispatch = useDispatch()
  const { sourceFinancingList } = useSelector (state => ({
    sourceFinancingList: state.ConfigInit.sourceFinancingList,
  }));
  //meta title
  document.title = "Configuración inicial | Municipio Transparente ";

  const [selectedFiles, setselectedFiles] = useState([])
  const [activeTab, setactiveTab] = useState("1");

  const toggle = tab => {
    if (activeTab !== tab) {
      setactiveTab(tab);
    }
    if( tab === "2" ) {
      dispatch(onGetAreas())
    }
  };


  function handleAcceptedFiles(files) {
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    )

    setselectedFiles(files)
  }

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }

  useEffect(()=> {
    dispatch(onGetConfigInit())
  },[]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs title="Dashboard" breadcrumbItem="Configuración" />
          <Row>
            <Col xl={12}>
              <Card>
                <CardBody>
                <Nav tabs>
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: activeTab === "1",
                        })}
                        onClick={() => {
                          toggle("1");
                        }}
                      >
                        Configuración Inicial
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: activeTab === "2",
                        })}
                        onClick={() => {
                          toggle("2");
                        }}
                      >
                        Áreas
                      </NavLink>
                    </NavItem>
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        active: activeTab === "3",
                      })}
                      onClick={() => {
                        toggle("3");
                      }}
                    >
                      Membrete para Oficio
                    </NavLink>
                  </NavItem>
                  </Nav>
                  <TabContent activeTab={activeTab} className="p-3 text-muted">
                    <TabPane tabId="1">
                      <Row>
                        <Col xs="12">
                          <FormCityhallConf edit={true} />
                        
                          <FormComissionFinance/>

                          <FormSourceFinancing sourceFinancingList={sourceFinancingList}/>
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane tabId="2">
                      <CatAreas/>
                    </TabPane>
                    <TabPane tabId="3">
                      <FormLetterheadConf/>
                    </TabPane>
                    </TabContent>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ConfigInit
