import React, { createContext, useContext, useState, useEffect } from "react";

// Crear el contexto
const ActiveCityhallContext = createContext();

// Hook personalizado para acceder fácilmente al contexto
export const useActiveCityhall = () => useContext(ActiveCityhallContext);

// Proveedor del contexto
export const ActiveCityhallProvider = ({ children }) => {
  const [activeCityhall, setActiveCityhall] = useState(() => {
    // Recuperar la empresa almacenada en el almacenamiento local (si existe)
    return JSON.parse(localStorage.getItem("cityhall")) || null;
  });

  useEffect(() => {
    // Guardar la empresa seleccionada en el almacenamiento local
    if (activeCityhall) {
      localStorage.setItem("cityhall", JSON.stringify(activeCityhall));
    } else {
      localStorage.removeItem("cityhall");
    }
  }, [activeCityhall]);

  return (
    <ActiveCityhallContext.Provider value={{ activeCityhall, setActiveCityhall }}>
      {children}
    </ActiveCityhallContext.Provider>
  );
};